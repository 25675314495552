import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ArrowLeft } from 'phosphor-react';
const tabStyles = {
    textTransform: 'none',
    minHeight: '56px',
    textAlign: 'left',
    borderBottom: '1px solid #B6C6CF',
    borderRadius: 0,
    '&:hover': {
        fontWeight: 500
    }
};
const AccountSideNav = () => {
    const navigate = useNavigate();
    const handleGoBack = () => navigate(-1);
    return (_jsxs(Tabs, { orientation: 'vertical', value: 1, children: [_jsx(Tab, { tabIndex: 0, onClick: handleGoBack, label: 'Close', icon: _jsx(ArrowLeft, { size: 18, style: { marginBottom: '1px' } }), iconPosition: 'start', sx: {
                    ...tabStyles,
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                } }), _jsx(Tab, { label: 'My Account', sx: {
                    ...tabStyles,
                    background: '#DAE5EB',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    paddingLeft: '25px'
                } })] }));
};
export default AccountSideNav;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import TooltipIcon from '../../public/badges/TooltipIcon.svg';
import { useSessionContext } from '../context/SessionContext';
import { useApiRequest } from './apiHook';
const useVerifyLogin = () => {
    const [sessionContext] = useSessionContext();
    const { publicRequest } = useApiRequest();
    const navigate = useNavigate();
    useEffect(() => {
        if (sessionContext.isAuthenticated) {
            navigate('/inquiries');
        }
    }, [sessionContext.isAuthenticated]);
    return async (studentClassId) => {
        return publicRequest({
            endpoint: `/student-classes/${studentClassId}/verify-link`,
            method: 'GET',
        }).catch(() => {
            console.error('Error occurred Validating');
        });
    };
};
const useLogin = () => {
    const [sessionContext, updateSessionContext] = useSessionContext();
    const { publicRequest } = useApiRequest();
    const navigate = useNavigate();
    useEffect(() => {
        if (sessionContext.isAuthenticated) {
            navigate('/inquiries');
        }
    }, [sessionContext.isAuthenticated]);
    return async (username, password, callback) => {
        return publicRequest({
            endpoint: '/students/login',
            method: 'POST',
            data: { username, password },
        }).then((result) => {
            const token = result.data.tokens;
            localStorage.setItem('AccessToken', token.AccessToken);
            localStorage.setItem('IdToken', token.IdToken);
            localStorage.setItem('RefreshToken', token.RefreshToken);
            localStorage.setItem('Expires', String(Date.now() + (1000 * 60 * 60))); // Now + 1 hour
            localStorage.setItem('LastVisitedOn', String(Date.now()));
            let redirectPath = '/inquiries';
            if (result.data.student.lastInquiryVisited) {
                redirectPath = `/dashboard/${result.data.student.lastInquiryVisited}`;
            }
            updateSessionContext({
                redirectPath,
                isAuthenticated: true,
                user: result.data.student,
            });
        }).catch((err) => {
            console.error(err);
            if (callback) {
                callback('Invalid Password');
            }
        });
    };
};
const useLogout = () => {
    return () => {
        localStorage.removeItem('AccessToken');
        localStorage.removeItem('IdToken');
        localStorage.removeItem('RefreshToken');
        localStorage.removeItem('Expires');
        localStorage.removeItem('LastVisitedOn');
        window.location.href = '/auth/login';
    };
};
const useSignUp = () => {
    const { publicRequest } = useApiRequest();
    return async (signUpParams) => {
        const { studentClassId, username, password, firstName, lastName } = signUpParams;
        return publicRequest({
            endpoint: `/students/signup/${studentClassId}`,
            method: 'POST',
            data: { username, password, firstName, lastName },
        });
    };
};
const useAuthFormikInputFieldBuilder = (formik) => {
    return (name, label, type, endAdornment, additionalProps) => (_jsxs(_Fragment, { children: [_jsx(Typography, { component: 'label', variant: 'h4', color: '#000', htmlFor: name, "aria-label": label, children: `${label} *` }), _jsxs(Box, { position: 'relative', children: [_jsx(TextField, { placeholder: label, fullWidth: true, required: true, id: name, name: name, "aria-label": `${label} input`, type: type, value: formik.values[name], onChange: formik.handleChange, error: !!formik.errors[name] && formik.touched[name], autoComplete: 'off', InputProps: {
                            endAdornment,
                        }, ...additionalProps }), _jsx(Typography, { color: 'error', role: 'alert', fontSize: '11px', sx: { position: 'absolute', bottom: '-20px', left: '5px' }, children: formik.touched[name] && formik.errors[name] })] })] }));
};
const useAuthFormikInputFieldBuilderHorizontal = (formik) => {
    const textFieldStyles = { width: '60%', marginLeft: 'auto' };
    return (name, label, type, tooltip, endAdornment, disabled = false) => (_jsxs(Box, { display: 'flex', alignItems: 'center', marginBottom: '1rem', children: [_jsx(Typography, { component: 'label', htmlFor: name, variant: 'h4', children: label }), tooltip ? (_jsx(Tooltip, { tabIndex: 0, title: tooltip, children: _jsx(Icon, { children: _jsx(TooltipIcon, {}) }) })) : null, _jsx(TextField, { disabled: disabled, value: formik.values[name], onChange: formik.handleChange, error: !!formik.errors[name] && formik.touched[name], helperText: formik.touched[name] && formik.errors[name], sx: textFieldStyles, type: type, name: name, id: name, autoComplete: 'off', InputProps: {
                    endAdornment
                } })] }));
};
export { useAuthFormikInputFieldBuilder, useAuthFormikInputFieldBuilderHorizontal, useLogin, useLogout, useSignUp, useVerifyLogin, };

import { useCallback, useContext, useEffect, useState } from 'react';
import { FeedbackContext } from '../context/FeedbackContext';
import { useApiRequest } from './apiHook';
const defaultBlankAnnotations = '<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><pdf-info xmlns="http://www.pdftron.com/pdfinfo" version="2" import-version="4" /><fields /><annots /><pages><defmtx matrix="1,0,0,-1,0,792" /></pages></xfdf>';
const useAssignmentApi = (assignmentId, inquiryId, callback) => {
    const { authRequest } = useApiRequest();
    const { handleSuccess, handleError } = useContext(FeedbackContext);
    const [annotations, setAnnotations] = useState(defaultBlankAnnotations);
    // Used to Prevent Update Right after create
    const [documentAndAnnotationsLoaded, setDocumentAndAnnotationsLoaded] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState({
        submissions: []
    });
    const determineInitialAssignmentContent = (data) => {
        if (window.location.hash !== '') {
            const hashID = window.location.hash.slice(1);
            const fromHash = data.assignmentContent
                .find((content) => (content._id === hashID));
            return fromHash ?? data.assignmentContent[0];
        }
        else {
            return data.assignmentContent[0];
        }
    };
    const fetchAssignmentData = async (mounted) => {
        authRequest({
            endpoint: `/students/content/inquiries/${inquiryId}/assignments/${assignmentId}`
        }).then(res => {
            if (res.status === 200 && mounted) {
                const initialAssignmentContent = determineInitialAssignmentContent(res.data);
                setSelectedAsset(initialAssignmentContent);
                callback(res.data);
            }
        }).catch(console.error);
    };
    useEffect(() => {
        let mounted = true;
        fetchAssignmentData(mounted);
        return () => { mounted = false; };
    }, []);
    /**
     * Performs an auto-save operation for annotations.
     * @param newAnnotations - The new annotations to be saved.
     * @remarks
     * This function utilizes the useCallback hook to ensure consistent reference equality
     * for the created function across renders. The autoSave function is called
     * with the newAnnotations parameter to trigger the auto-save process.
     * The function will be re-created if the activeSubmission value changes.
     */
    const handleAutoSaveAnnotations = useCallback((newAnnotations) => {
        // If the edited assignment has been submitted, create a new submission before persisting changes:
        const latestSubmission = selectedAsset.submissions[0];
        if (!latestSubmission || !!latestSubmission.submittedOn) {
            createSubmission(newAnnotations)
                .then((updatedSelectedAsset) => {
                setSelectedAsset(updatedSelectedAsset);
            });
        }
        else {
            // if the current piece of work has not yet been submitted, simply overwrite annotations:
            updateAssignment(newAnnotations)
                .then((updatedSelectedAsset) => {
                setSelectedAsset(updatedSelectedAsset);
            });
        }
    }, [selectedAsset]);
    useEffect(() => {
        const latestSubmissionAnnotations = selectedAsset.submissions[0]?.submissionData;
        if (documentAndAnnotationsLoaded && ![defaultBlankAnnotations, '', latestSubmissionAnnotations].includes(annotations)) {
            handleAutoSaveAnnotations(annotations);
        }
    }, [annotations]);
    /**
     * Creates a submission with new annotations for the selected asset.
     * @param newAnnotations - The new annotations to be included in the submission.
     * @param setSubmissionState - If true, the function will set the current state
     * calls won't set the Active Submission after creation
     * @returns A promise that resolves to null or completes with no value.
     * @remarks
     * This function asynchronously creates a submission by sending a POST request to the server.
     * It takes the `newAnnotations` parameter, representing the annotations to be included in the submission.
     * If the `selectedAsset` is truthy, it constructs an API endpoint based on the `assignmentId` and `selectedAsset._id`.
     * Then, it sends the `newAnnotations` as the submission data in the POST request using the `authRequest` function.
     * If the response is successful and contains data, the `activeSubmissions` state is updated with the submission ID and submissions from the response.
     * Additionally, the `annotations` state is updated with `newAnnotations`, and the `hasSubmission` state is set to true.
     * If the `selectedAsset` is falsy, the function resolves the Promise with null.
     */
    const createSubmission = async (newAnnotations) => {
        if (selectedAsset._id) {
            const endpoint = `/students/content/assignment/${assignmentId}/assignment-content/${selectedAsset._id}/active-content/${selectedAsset.activeContentId}`;
            const data = {
                submission: newAnnotations,
            };
            return authRequest({
                endpoint,
                method: 'POST',
                data,
            }).then((resp) => {
                if (resp) {
                    const updatedSelectedAsset = {
                        ...selectedAsset,
                        submissionId: resp.data.studentSubmission._id,
                        updatedAt: resp.data.studentSubmission.updatedAt,
                        submissions: resp.data.studentSubmission.submissions,
                    };
                    return updatedSelectedAsset;
                }
                return null;
            });
        }
        return Promise.resolve(null);
    };
    /**
     * Performs an auto-save operation for the active submission with new annotations.
     * @param newAnnotations - The new annotations to be auto-saved.
     * @remarks
     * This function is responsible for performing an auto-save operation for the active submission.
     * It takes the `newAnnotations` parameter, representing the annotations to be auto-saved.
     * If both `activeSubmission._id` and `activeSubmission.submissions[0]._id` are truthy,
     * it constructs the API endpoint using the `activeSubmission.submissionId` and `activeSubmission.submissions[0]._id`.
     * Then, it creates a data object containing the `submissionData` with the newAnnotations.
     * The `authRequest` function is called with the constructed endpoint, method set to 'PUT', and the data object.
     * If the auto-save request is successful, the `annotations` state is updated with the newAnnotations.
     * Note: This function assumes the existence of `activeSubmission` and its required properties. Please ensure the data is properly set before invoking this function.
     */
    const updateAssignment = (newAnnotations) => {
        const endpoint = `/students/content/submission/${selectedAsset.submissionId}/entry/${selectedAsset.submissions[0]._id}`;
        const data = {
            submissionData: newAnnotations,
        };
        return authRequest({
            endpoint,
            method: 'PUT',
            data,
        }).then((resp) => {
            return {
                ...selectedAsset,
                submissionId: resp.data.studentSubmission._id,
                submissions: resp.data.studentSubmission.submissions,
                updatedAt: resp.data.studentSubmission.updatedAt,
            };
        });
    };
    // Sets status to "unreviewed" and applies a new "submittedOn" date
    const submitAssignment = async () => {
        const submit = (updatedSelectedAsset) => {
            const endpoint = `/students/content/submission/${updatedSelectedAsset.submissionId}/entry/${updatedSelectedAsset.submissions[0]._id}`;
            const data = {
                submissionData: annotations,
                submittedOn: new Date(),
                submissionStatus: 'unreviewed',
            };
            authRequest({
                endpoint,
                method: 'PUT',
                data,
            }).then((resp) => {
                if (resp.status === 200) {
                    setSelectedAsset({
                        ...updatedSelectedAsset,
                        submissionId: resp.data.studentSubmission._id,
                        submissions: resp.data.studentSubmission.submissions,
                        updatedAt: resp.data.studentSubmission.updatedAt,
                    });
                    setAnnotations(updatedSelectedAsset.submissions[0].submissionData);
                    handleSuccess('Attachment submitted!');
                }
                else {
                    console.error(resp.data);
                    handleError('Could not submit attachment');
                }
            });
        };
        //  PRETTY GOOD TO GO SO FAR
        if (!selectedAsset.submissions[0]) {
            await createSubmission(defaultBlankAnnotations).then((newActiveSubmission) => {
                submit(newActiveSubmission);
            });
        }
        else if (selectedAsset.submissions[0].submittedOn) {
            await createSubmission(annotations).then((newActiveSubmission) => {
                submit(newActiveSubmission);
            });
        }
        else {
            submit(selectedAsset);
        }
        return;
    };
    return {
        selectedAsset,
        setSelectedAsset,
        setAnnotations,
        submitAssignment,
        setDocumentAndAnnotationsLoaded,
    };
};
export default useAssignmentApi;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { CaretRight } from 'phosphor-react';
import { setDocumentTitle } from '../../helpers/documentHelper';
import { useApiRequest } from '../../hooks/apiHook';
import * as moduleStyles from './module.styles';
import ModuleAssignmentStatus from './ModuleAssignmentStatus';
const AccordionGroup = ({ inquiryId, moduleId, lessonData }) => {
    return (_jsx(Box, { tabIndex: 0, "aria-label": 'Module Assignment List', sx: moduleStyles.accordionGroupStyles, children: lessonData.map((lessonItem, lessonIndex) => (_jsxs(Accordion, { defaultExpanded: true, sx: { borderLeft: 'none', borderRight: 'none' }, children: [_jsx(AccordionSummary, { expandIcon: _jsx(CaretRight, { size: 16 }), sx: moduleStyles.accordionSummaryStyles, children: _jsx(Typography, { component: 'h2', variant: 'h4', marginLeft: '24px', children: lessonItem.title }) }), _jsx(AccordionDetails, { sx: moduleStyles.accordionDetailsStyles, children: lessonItem.assignments.map((assignment, index) => (_jsxs(Box, { component: 'a', href: `/assignment/${inquiryId}/${moduleId}/${assignment.assignmentId}`, sx: moduleStyles.moduleHomepageAssignmentWrapper((lessonItem.assignments.length - 1 === index), (lessonData.length - 1 === lessonIndex)), children: [_jsx(Box, { width: '35%', sx: moduleStyles.moduleHomepageAssignmentTitle, children: _jsx(Typography, { children: assignment.assignmentTitle }) }), _jsx(Box, { sx: moduleStyles
                                    .moduleHomepageAssignmentDueDate(assignment.dueDate), children: assignment.dueDate && (_jsxs(Typography, { children: ['Due:', ' ', assignment.dueTime ? dayjs(assignment.dueDate).format('MMM D, h:mmA') : dayjs(assignment.dueDate).format('MMM D')] })) }), _jsx(Box, { sx: moduleStyles.moduleHomepageAssignmentIcons, children: _jsx(ModuleAssignmentStatus, { assets: assignment.assets }) })] }, `assignment-item-${uuid()}`))) })] }, `lesson-item-${uuid()}`))) }));
};
const ModuleOverview = () => {
    const params = useParams();
    const { inquiryId, moduleId } = params;
    const { authRequest } = useApiRequest();
    const [moduleHomepageData, setModuleHomepageData] = useState({
        inquiryData: {
            _id: '',
            images: {
                original: '',
                overlay: '',
                title: '',
            },
        },
        moduleData: {
            moduleId: '',
            moduleTitle: '',
            moduleNumber: '',
            lessonData: [],
        },
    });
    const getUnitData = useCallback(() => {
        return authRequest({
            endpoint: `/students/content/inquiries/${inquiryId}/module/${moduleId}`,
        });
    }, []);
    useEffect(() => {
        let mounted = true;
        getUnitData()
            .then((moduleHomepageResponse) => {
            if (mounted && moduleId) {
                setModuleHomepageData(moduleHomepageResponse.data);
            }
        }).catch(console.error);
        return () => { mounted = false; };
    }, [moduleId]);
    useEffect(() => {
        setDocumentTitle(document.title = 'inquirED student module assignments ');
    }, []);
    if (!moduleHomepageData.moduleData.moduleId) {
        return (null);
    }
    return (_jsx(Box, { role: 'main', tabIndex: 0, sx: moduleStyles.moduleHomepageWrapper, children: _jsxs(Box, { sx: moduleStyles.moduleHomepageAssignmentsWrapper, children: [_jsxs(Box, { sx: moduleStyles.moduleHomepageHeader(moduleHomepageData.inquiryData.images.overlay), children: [_jsx(Typography, { variant: 'h1', sx: moduleStyles.moduleHomepageSubtitle, children: `${moduleHomepageData.moduleData.moduleNumber} Assignments` }), _jsx(Typography, { sx: moduleStyles.moduleHomepageTitle, children: moduleHomepageData.moduleData.moduleTitle })] }), moduleHomepageData &&
                    _jsx(AccordionGroup, { inquiryId: moduleHomepageData.inquiryData._id, moduleId: moduleHomepageData.moduleData.moduleId, lessonData: [...moduleHomepageData.moduleData.lessonData] }), !(moduleHomepageData.moduleData.lessonData.length > 0) && (_jsx(Box, { sx: moduleStyles.moduleHomepageNoAssignments, children: _jsx(Typography, { variant: 'body2', fontSize: '15px', children: 'No assignments!' }) }))] }) }));
};
export default ModuleOverview;

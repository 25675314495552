import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext, useRef, useState, } from 'react';
import Dialog from '@mui/material/Dialog';
/**
 * Create DialogContext from DialogProviderContext
 * Default values:
 * - createDialog: () => {}
 * - closeDialog: () => {}
 * - isOpen: () => false
 */
const DialogContext = createContext([
    () => { },
    () => { },
    () => false,
]);
const DialogContainer = (props) => {
    const { children, open, onClose } = props;
    return (_jsx(Dialog, { open: open, onClose: onClose, children: children }));
};
export const useDialog = () => useContext(DialogContext);
/**
 * DialogProvider serves as a context provider for managing dialogs.
 * It accepts `children` as a prop, which are the components wrapped by this provider.
 *
 * The provider maintains the state of the dialog using the useState hook, with the following properties:
 * 1. createDialog: A function to create and display a new dialog, taking a DialogOption object as an argument.
 * 2. closeDialog: A function to close the currently open dialog.
 * 3. isOpen: A function that returns a boolean value indicating whether a dialog is currently open.
 *
 * The provider's value is memoized using the useRef hook to avoid unnecessary re-renders.
 * If a dialog is open, the DialogContainer component will be rendered with the appropriate props.
 */
export default function DialogProvider({ children }) {
    const [dialog, setDialog] = useState();
    const createDialog = (option) => {
        setDialog({ ...option, open: true });
    };
    const closeDialog = () => {
        setDialog({ ...dialog, open: false });
    };
    const isOpen = () => dialog?.open ?? false;
    const contextValue = useRef([createDialog, closeDialog, isOpen]);
    return (_jsxs(DialogContext.Provider, { value: contextValue.current, children: [children, dialog &&
                _jsx(DialogContainer, { onClose: closeDialog, ...dialog })] }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { moduleCardWrapper } from './module.styles';
import ModuleStatusIcons from './ModuleStatusIcons';
const ModuleCard = ({ module, inquiryId }) => {
    return (_jsx(Box, { sx: moduleCardWrapper, children: _jsx(Link, { href: `/overview/${inquiryId}/${module._id}`, sx: { outline: 'none' }, underline: 'none', children: _jsxs(Box, { sx: { padding: '24px' }, children: [_jsx(Typography, { component: 'h2', variant: 'h3', children: module.moduleNumber }), _jsx(Typography, { component: 'h3', variant: 'subtitle1', children: module.title }), _jsx(ModuleStatusIcons, { statusItems: module.statusItems })] }) }) }));
};
export default ModuleCard;

import { jsx as _jsx } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
/**
 * @remarks Display various dates where with a Today or Yesterday as appropriate
 * @param heading eg: 'Last Updated'
 */
const FormattedDate = ({ heading, date }) => {
    if (!date)
        return null;
    const handleDateFormat = (date) => {
        const parsedDate = dayjs(date);
        if (parsedDate.isSame(dayjs(), 'day')) {
            return parsedDate.format('[today at] h:mmA');
        }
        else if (parsedDate.isSame(dayjs().subtract(1, 'day'), 'day')) {
            return parsedDate.format('[yesterday at] h:mmA');
        }
        return parsedDate.format('MMM D, YYYY [at] h:mmA');
    };
    return (_jsx(Typography, { variant: 'subtitle2', children: `${heading} ${handleDateFormat(date)}` }));
};
export default FormattedDate;

import theme from '../../theme/customTheme';
export const skipLinkStyles = {
    position: 'absolute',
    left: '-9999px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    padding: '5px',
    background: 'white',
    '&:focus': {
        position: 'absolute',
        left: '0px',
        top: '0px',
        width: 'auto',
        height: 'auto',
        overflow: 'visible',
    }
};
export const sessionMenuButtonStyles = {
    backgroundColor: '#EBEFF3',
    fontWeight: 700,
    borderRadius: '25px',
    fontSize: '16px',
    padding: '10px 20px',
    letterSpacing: '-0.1px',
    height: '48px',
    [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        padding: '0',
        letterSpacing: '-1px',
        height: '36px',
        minWidth: '36px',
    },
    '&:hover': {
        backgroundColor: '#d7dbe0'
    },
    '&:focus': {
        outline: '2px solid blue',
    },
};
export const toolbarStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    height: '80px',
    padding: '0px 25px 0px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        height: '56px'
    },
    [theme.breakpoints.down('sm')]: {
        padding: '0px 10px 0px'
    }
};
export const returnButtonStyles = {
    marginLeft: '15px',
    borderRadius: '24px',
    '&:focus': {
        outline: '2px solid blue',
    },
};
export const returnButtonStylesAssignment = {
    marginLeft: '15px',
    borderRadius: '24px',
    [theme.breakpoints.down('sm')]: { marginLeft: 0 }
};

export var IdentityProvider;
(function (IdentityProvider) {
    IdentityProvider["CLASSLINK"] = "ClassLink";
    IdentityProvider["CLEVER"] = "Clever";
})(IdentityProvider || (IdentityProvider = {}));
export var SSOuserRole;
(function (SSOuserRole) {
    SSOuserRole["STUDENT"] = "student";
    SSOuserRole["TEACHER"] = "teacher";
})(SSOuserRole || (SSOuserRole = {}));

import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState } from 'react';
export const FeedbackContext = createContext({
    feedback: { message: '', severity: 'info', open: false },
    setFeedback: () => { },
    handleError: () => { },
    handleSuccess: () => { }
});
export const FeedbackContextProvider = ({ children }) => {
    const [feedback, setFeedback] = useState({
        message: '',
        severity: 'info',
        open: false,
    });
    const handleError = (message = 'Unable to complete request. Please refresh and try again.') => {
        setFeedback({
            message,
            severity: 'error',
            open: true
        });
    };
    const handleSuccess = (message) => {
        setFeedback({
            message,
            severity: 'success',
            open: true
        });
    };
    const defaultContext = {
        feedback,
        setFeedback,
        handleError,
        handleSuccess
    };
    return (_jsx(FeedbackContext.Provider, { value: defaultContext, children: children }));
};

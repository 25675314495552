import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { ExpandLess } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import theme from '../../theme/customTheme';
import DrawerAssetItem from './DrawerAssetItem';
const expandMoreStyles = {
    height: '20px',
    width: '20px',
    padding: 0,
    marginRight: '5px',
    background: 'none !important'
};
const AssignmentVerticalDrawer = ({ assignmentData, selectedAsset, setSelectedAsset }) => {
    const [expanded, setExpanded] = useState(false);
    const handleExpandDetails = () => {
        setExpanded(!expanded);
    };
    const sortedAssignmentContent = [...assignmentData.assignmentContent].sort((a, b) => {
        const assetTitleA = a.assetTitle.toLowerCase();
        const assetTitleB = b.assetTitle.toLowerCase();
        if (assetTitleA < assetTitleB) {
            return -1;
        }
        if (assetTitleA > assetTitleB) {
            return 1;
        }
        return 0;
    });
    return (_jsxs(Box, { sx: {
            padding: '12px 24px 0px 24px',
            borderBottom: '1px solid #ddd',
            [theme.breakpoints.up('sm')]: { display: 'none' },
        }, children: [_jsx(Typography, { component: 'p', variant: 'h4', children: assignmentData.lessonNumber }), _jsx(Typography, { component: 'h1', variant: 'h3', children: assignmentData.assignmentTitle }), _jsxs(Box, { display: 'flex', alignItems: 'center', padding: '5px 0px', children: [_jsx(IconButton, { color: 'primary', sx: expandMoreStyles, disableRipple: true, onClick: handleExpandDetails, children: expanded ? _jsx(ExpandLess, {}) : _jsx(ExpandMoreIcon, {}) }), _jsx(Typography, { fontWeight: 500, fontSize: '13px', color: 'primary', children: `${expanded ? 'Close' : 'Expand'} Assignment Details` })] }), _jsxs(Collapse, { in: expanded, children: [_jsxs(Box, { children: [_jsxs(Typography, { component: 'p', variant: 'subtitle2', marginTop: '8px', children: ['Published on', ' ', dayjs(assignmentData.publishedOn).format('MMM D, YYYY [at] h:mmA')] }), assignmentData.dueDate && (_jsxs(Typography, { component: 'p', variant: 'h4', marginTop: '16px', children: ['Due on', ' ', _jsx(Typography, { variant: 'body2', fontSize: '15px', component: 'span', children: dayjs(assignmentData.dueDate).format('dddd, MMM D [at] h:mmA') })] })), _jsxs(Box, { marginTop: '14px', children: [_jsx(Typography, { component: 'h2', variant: 'h4', gutterBottom: true, children: 'Directions' }), _jsx(Typography, { variant: 'body2', whiteSpace: 'pre-wrap', gutterBottom: true, children: assignmentData.directions })] })] }), _jsx(Box, { className: 'assignment-asset-list', children: sortedAssignmentContent.map((asset, index) => (_jsx(DrawerAssetItem, { asset: asset, collapsed: false, selectedAsset: selectedAsset, handleSelectAsset: () => setSelectedAsset(asset) }, index))) })] })] }));
};
export default AssignmentVerticalDrawer;

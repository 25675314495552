import theme from '../../theme/customTheme';
const containerStyles = {
    height: '100vh',
    display: 'flex',
    backgroundColor: '#F6F8FA',
};
const inquirEDBannerStyles = {
    width: '60%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    [theme.breakpoints.down('md')]: {
        width: '100%'
    },
};
const authDivWrapperStyles = {
    width: '40%',
    height: '100%',
    overflowY: 'overlay',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        position: 'absolute',
    },
};
const authDivStyles = {
    alignItems: 'center',
    padding: '50px 60px',
    background: '#F6F8FA',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
        width: '50%',
        minWidth: '30rem',
        position: 'relative',
        top: '50px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
        minWidth: 'unset',
        width: 'fill-available',
        margin: '0 20px',
    },
    [theme.breakpoints.down('xs')]: {
        width: '90%',
        padding: '20px',
    },
    '@media (max-height: 900px)': {
        padding: '25px 30px',
    },
    '@media (max-height: 500px)': {
        top: '5px',
        padding: '5px 20px'
    }
};
const welcomeLogoStyles = {
    width: '8rem',
    [theme.breakpoints.down('md')]: {
        '@media (max-height: 900px)': {
            display: 'none',
        }
    }
};
const welcomeMessageStyles = {
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: '12px',
    '@media (max-height: 500px)': {
        marginBottom: '0px',
    }
};
const loginFormStyles = {
    marginTop: '12px',
};
const questionsAboutLoginStyles = {
    textAlign: 'center',
    marginBottom: '10px',
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'italic',
    color: theme.palette.primary.main,
};
const loginSubmitDivStyles = {
    marginBottom: '25px',
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-height: 500px)': { marginBottom: '5px' }
};
const disclaimerStyles = {
    color: '#58606A',
    position: 'relative',
    fontSize: '14px',
    marginTop: '12px',
    '@media (max-height: 500px)': {
        marginTop: '10px',
        fontSize: '13px'
    }
};
const incorrectCredentialsStyles = {
    fontSize: '14px',
    fontWeight: '500',
    color: '#BD3D44',
};
const signUpFormStyles = {
    marginTop: '30px',
    [theme.breakpoints.down('md')]: {
        marginTop: '10px',
    },
    '@media (max-height: 900px)': {
        marginTop: '0px'
    },
};
const ssoButtonStyles = {
    paddingLeft: '56px',
    minHeight: '56px',
    marginTop: '12px',
    color: theme.palette.primary.main,
};
const ssoButtonIconStyles = {
    left: '12px',
    transform: 'translateY(-50%)',
    width: '32px',
    height: '32px',
};
export { authDivStyles, authDivWrapperStyles, containerStyles, disclaimerStyles, incorrectCredentialsStyles, inquirEDBannerStyles, loginFormStyles, loginSubmitDivStyles, questionsAboutLoginStyles, signUpFormStyles, ssoButtonIconStyles, ssoButtonStyles, welcomeLogoStyles, welcomeMessageStyles };

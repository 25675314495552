import theme from '../../theme/customTheme';
export const contentContainerStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'fill-available',
    height: '100%',
    padding: '73px 40px 40px 40px !important',
    [theme.breakpoints.down('md')]: {
        padding: '73px 20px 20px 20px !important',
    },
};
export const headingStyles = {
    color: theme.palette.primary.main,
    marginTop: '24px',
    marginBottom: '10px',
};
export const bodyStyles = {
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginBottom: '24px',
    maxWidth: '520px',
    fontSize: '15px',
};
export const graphicBackgroundStyles = {
    height: '138px',
    width: '138px',
    position: 'relative'
};
export const graphicForegroundStyles = {
    position: 'absolute',
};
export const footerStyles = {
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontStyle: 'italic',
    marginTop: '48px',
};

import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Link } from '@mui/material';
import HotAirBalloonGraphic from './HotAirBalloonGraphic';
import WhoopsGraphic from './WhoopsGraphic';
const errorContentMap = new Map([
    ['Looks like you no longer have access to this class or it no longer exists.', {
            footer: 'If you\'re not sure how to log in or you think this is a mistake, talk to your teacher.',
            graphic: _jsx(HotAirBalloonGraphic, {}),
            heading: 'Oops',
            primaryButton: (_jsx(Button, { "data-testid": 'error-dialog-login-button', role: 'link', href: '/login', variant: 'contained', children: 'Go to Login' })),
            secondaryButton: null,
        }],
    ['It looks like you haven\'t been added to any classes yet. Talk to your teacher so they can add you to their class.', {
            footer: 'If you\'re not sure how to log in or you think this is a mistake, talk to your teacher.',
            graphic: _jsx(HotAirBalloonGraphic, {}),
            heading: 'Oops',
            primaryButton: (_jsx(Button, { "data-testid": 'error-dialog-login-button', role: 'link', href: '/login', variant: 'contained', children: 'Go to Login' })),
            secondaryButton: null,
        }],
    ['Looks like something went wrong. Please tell your teacher so they can help fix it.', {
            footer: null,
            graphic: _jsx(HotAirBalloonGraphic, {}),
            heading: 'Oops - District Setup Error',
            primaryButton: null,
            secondaryButton: (_jsx(Link, { role: 'link', href: '/login', sx: { fontWeight: '500', mt: 3 }, children: 'Return to Login' })),
        }],
    ['Oops, it looks like you might be in the wrong place.', {
            footer: null,
            graphic: _jsx(WhoopsGraphic, {}),
            heading: 'No Access',
            primaryButton: (_jsx(Button, { "data-testid": 'error-dialog-back-button', role: 'link', href: '/auth/login', variant: 'contained', children: 'Go Back' })),
            secondaryButton: (_jsx(Link, { role: 'link', href: `${process.env.BIG_DESK_URL}`, sx: { fontWeight: '500', mt: 3 }, children: 'Log in as a Teacher' })),
        }],
]);
export default errorContentMap;

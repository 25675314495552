import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import theme from '../../theme/customTheme';
import NavigationBar from '../NavigationBar/NavigationBar';
const ProtectedRoute = ({ isAuthenticated, authenticationPath, redirectPath, setRedirectPath }) => {
    const currentLocation = useLocation();
    useEffect(() => {
        if (!isAuthenticated) {
            setRedirectPath(currentLocation.pathname);
        }
    }, [isAuthenticated, setRedirectPath, currentLocation, redirectPath]);
    const containerStyles = {
        position: 'relative',
        marginTop: '80px',
        height: 'calc(100vh - 80px)',
        display: 'flex',
        backgroundColor: '#F6F8FA',
        [theme.breakpoints.down('md')]: {
            marginTop: '64px',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '56px',
            height: '100%',
        },
    };
    return isAuthenticated
        ? (_jsxs(Container, { disableGutters: true, maxWidth: false, sx: containerStyles, children: [_jsx(NavigationBar, {}), _jsx(Outlet, {})] })) : (_jsx(Navigate, { to: { pathname: isAuthenticated
                ? redirectPath
                : authenticationPath
        } }));
};
export default ProtectedRoute;

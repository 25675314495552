import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ArrowSquareOut } from 'phosphor-react';
import { getAssetIcon } from '../../services/asset.service';
import { openNewTabWrapperStyles } from './assignment.styles';
const ExternalAsset = ({ asset }) => {
    return (_jsxs(Box, { width: '100%', children: [_jsxs(Box, { sx: { padding: '18px 15px', display: 'flex', alignItems: 'center' }, children: [getAssetIcon(asset.assetType, asset.assetFileType, { stroke: '#000' }), _jsx(Typography, { marginLeft: '10px', children: asset.assetTitle })] }), _jsx(Box, { sx: openNewTabWrapperStyles, children: _jsxs(Link, { href: asset.assetLink, target: '_blank', sx: { display: 'flex', alignItems: 'center' }, children: [_jsx(Typography, { variant: 'subtitle1', sx: { textDecoration: 'underline', marginRight: '10px' }, children: 'Open in a new tab' }), _jsx(ArrowSquareOut, { size: 24 })] }) })] }));
};
export default ExternalAsset;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import bitmap from '../../../public/images/Bitmap.png';
import InquiredMainLogoSmall from '../../../public/logos/inquirED_mainlogo_small.svg';
import { FeedbackContext } from '../../context/FeedbackContext';
import { useQuery } from '../../hooks/apiHook';
import Feedback from '../Alert/Feedback';
import { authDivStyles, authDivWrapperStyles, containerStyles, inquirEDBannerStyles, welcomeLogoStyles, } from './auth.styles';
const AuthContainer = () => {
    const { setFeedback } = useContext(FeedbackContext);
    const query = useQuery();
    const errorMessage = query.get('error');
    useEffect(() => {
        if (errorMessage) {
            setFeedback({
                message: errorMessage,
                severity: 'error',
                open: true,
                disableAutoHide: true,
            });
        }
    }, [errorMessage]);
    return (_jsxs(Container, { disableGutters: true, maxWidth: false, sx: containerStyles, children: [_jsxs(Box, { display: 'flex', width: '100%', children: [_jsx(Box, { sx: authDivWrapperStyles, children: _jsxs(Box, { sx: authDivStyles, children: [_jsx(Box, { role: 'banner', sx: welcomeLogoStyles, children: _jsx(InquiredMainLogoSmall, { "aria-label": 'inquirED logo' }) }), _jsx(Box, { display: 'flex', flexDirection: 'column', maxWidth: '30rem', children: _jsx(Outlet, {}) })] }) }), _jsx(Box, { role: 'img', "aria-label": 'city skyline painting', sx: { backgroundImage: `url(${bitmap})`, ...inquirEDBannerStyles } })] }), _jsx(Feedback, {})] }));
};
export default AuthContainer;

import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiRequest } from '../hooks/apiHook';
import { initialSession } from '../typings/sessionTypings';
export const SessionContext = createContext([
    initialSession,
    () => { }
]);
export const useSessionContext = () => useContext(SessionContext);
export const SessionContextProvider = (props) => {
    const [sessionState, setSessionState] = useState(initialSession);
    const defaultSessionContext = [
        sessionState,
        setSessionState
    ];
    const { authRequest } = useApiRequest();
    const navigate = useNavigate();
    useEffect(() => {
        if (initialSession.isAuthenticated) {
            authRequest({ endpoint: '/students/me' })
                .then((res) => {
                setSessionState({
                    ...sessionState,
                    user: res.data,
                });
            });
        }
    }, []);
    useEffect(() => {
        if (sessionState.isAuthenticated && sessionState.user.lastInquiryVisited) {
            navigate(sessionState.redirectPath);
        }
    }, [sessionState.isAuthenticated]);
    return (_jsx(SessionContext.Provider, { value: defaultSessionContext, children: props.children }));
};

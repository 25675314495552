import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { decodeToken, isExpired, setTokenStorage } from '../helpers/authHelpers';
const getAuth = () => {
    return {
        'Authorization': 'Bearer ' + localStorage.getItem('AccessToken'),
        'X-ID-Token': String(localStorage.getItem('IdToken')),
        'X-Refresh-Token': String(localStorage.getItem('RefreshToken')),
    };
};
const authRequest = async ({ endpoint, method, data, headers }) => {
    const currentToken = decodeToken('AccessToken');
    if (isExpired() && currentToken) {
        const refreshConfig = {
            method: 'POST',
            url: `${process.env.API_URL}/refresh`,
            headers: getAuth(),
            data: {
                userId: currentToken.username
            }
        };
        await axios.request(refreshConfig).then((result) => {
            if (result.data.refreshed) {
                const tokenData = {
                    AuthenticationResult: {
                        ...result.data.AuthenticationResult,
                        RefreshToken: window.localStorage.getItem('RefreshToken'),
                    },
                    ChallengeParameters: result.data.ChallengeParameters,
                };
                setTokenStorage(tokenData);
            }
            localStorage.setItem('Expires', String(Date.now() + (1000 * 60 * 60))); // Now + 1 hour
        }).catch(() => {
            localStorage.removeItem('AccessToken');
            localStorage.removeItem('IdToken');
            localStorage.removeItem('RefreshToken');
            localStorage.removeItem('DemoCode');
            localStorage.removeItem('Expires');
            window.location.pathname = '/auth/login';
        });
    }
    const config = {
        method,
        url: `${process.env.API_URL}${endpoint}`,
        headers: {
            ...getAuth(),
            ...headers ?? {},
        },
        data
    };
    return axios.request(config);
};
const publicRequest = async ({ endpoint, method, data, headers }) => {
    const config = {
        method,
        url: process.env.API_URL + endpoint,
        headers: headers ?? {},
        data
    };
    return axios.request(config);
};
export const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};
export const useApiRequest = () => {
    return {
        authRequest,
        publicRequest,
        useQuery,
    };
};

import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import DialogProvider from './components/Dialog/DialogProvider';
import { FeedbackContextProvider } from './context/FeedbackContext';
import { ModuleDataContextProvider } from './context/ModuleDataContext';
import { SessionContextProvider } from './context/SessionContext';
import theme from './theme/customTheme';
import App from './App';
ReactDOM.render(_jsx(BrowserRouter, { children: _jsx(SessionContextProvider, { children: _jsx(FeedbackContextProvider, { children: _jsx(ThemeProvider, { theme: theme, children: _jsx(DialogProvider, { children: _jsx(ModuleDataContextProvider, { children: _jsx(App, {}) }) }) }) }) }) }), document.getElementById('root'));

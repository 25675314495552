import { jsx as _jsx } from "react/jsx-runtime";
import { useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';
import errorContentMap from './errorContentMap';
import FullScreenErrorDialog from './FullScreenErrorDialog';
import HotAirBalloonGraphic from './HotAirBalloonGraphic';
const parseErrorContent = (errorMessage) => {
    let footer = null;
    let graphic = null;
    let heading = null;
    let primaryButton = null;
    let secondaryButton = null;
    const errorContent = errorContentMap.get(errorMessage);
    if (errorContent) {
        footer = errorContent.footer;
        graphic = errorContent.graphic;
        heading = errorContent.heading;
        primaryButton = errorContent.primaryButton;
        secondaryButton = errorContent.secondaryButton;
    }
    else {
        footer = 'If you\'re not sure how to log in or you think this is a mistake, talk to your teacher.';
        graphic = _jsx(HotAirBalloonGraphic, {});
        heading = 'Oops';
        primaryButton = (_jsx(Button, { "data-testid": 'error-dialog-login-button', role: 'link', href: '/login', variant: 'contained', children: 'Go to Login' }));
    }
    return {
        body: errorMessage,
        footer,
        graphic,
        heading,
        primaryButton,
        secondaryButton,
    };
};
const WrongLink = () => {
    const [searchParams] = useSearchParams();
    const errorMessageParam = searchParams.get('message');
    const errorContent = parseErrorContent(errorMessageParam ?? 'Looks like you no longer have access to this class or it no longer exists.');
    return (_jsx(FullScreenErrorDialog, { documentTitle: 'Login error', graphic: errorContent.graphic, heading: errorContent.heading, body: errorContent.body, primaryButton: errorContent.primaryButton, secondaryButton: errorContent.secondaryButton, footer: errorContent.footer }));
};
export default WrongLink;

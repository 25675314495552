import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import BlueOvalBackground from './BlueOvalBackground';
import { graphicForegroundStyles } from './warningsStyles';
import HotAirBalloon from '/public/icons/HotAirBalloon.svg';
const HotAirBalloonGraphic = () => {
    return (_jsx(BlueOvalBackground, { children: _jsx(Box, { sx: {
                ...graphicForegroundStyles,
                bottom: '0px',
                left: '46px',
                height: '125px',
                width: '72px',
            }, children: _jsx(HotAirBalloon, {}) }) }));
};
export default HotAirBalloonGraphic;

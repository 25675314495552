import theme from '../../theme/customTheme';
const assignmentContainerStyles = {
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    borderRadius: '12px',
    width: '100%',
    margin: '24px',
    background: '#FFF',
    border: '1px solid #DDD',
    display: 'flex',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
        margin: '0px',
        borderRadius: '0px'
    },
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginTop: '10px',
        borderRadius: '10px 10px 0px 0px',
        overflowY: 'auto',
        overflowX: 'hidden',
    }
};
const assignmentDrawerStyles = (collapsed) => ({
    position: 'relative',
    zIndex: 100,
    flexShrink: 0,
    width: '316px',
    padding: collapsed ? '45px 25px 12px 24px' : '45px 17px 12px 24px',
    borderRight: '1px solid #DDD',
    left: collapsed ? '-255px' : '0px',
    overflowY: 'overlay',
    transition: '500ms',
    background: '#FFFFFF',
    [theme.breakpoints.down('md')]: {
        position: 'absolute',
        top: 0,
        height: '100%',
        boxShadow: '5px 2px 15px -3px #00000082',
    },
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
});
const collapseArrowStyles = (collapsed) => ({
    position: 'absolute',
    top: 10,
    left: collapsed ? 320 : 310,
    transform: collapsed ? 'rotate(-180deg) scale(.9)' : 'rotate(0deg) scale(.9)',
    transition: 'transform 300ms, left 500ms',
});
const pdfViewerStyles = (display) => ({
    height: '100%',
    width: '100%',
    flex: 'auto',
    display
});
const viewerWrapperStyles = {
    width: '100%',
    height: 'calc(100% - 124px)',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    background: '#F8F9FA',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '70px',
        height: 'fit-content',
    }
};
const openNewTabWrapperStyles = {
    padding: '18px 30px',
    backgroundColor: '#F8F9FA',
    display: 'flex',
    justifyContent: 'flex-end'
};
const assignmentContentWrapper = (collapsed) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: collapsed ? '-255px' : '0px',
    transition: '500ms',
    [theme.breakpoints.down('md')]: {
        marginLeft: '110px',
        width: 'calc(100% - 110px)',
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        width: '100%',
        minHeight: '200px',
    }
});
const drawerAssetItemStyles = (collapsed, selected, color) => ({
    position: 'relative',
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'stretch',
    overflow: 'hidden',
    width: collapsed ? '86px' : '318px',
    minHeight: '70px',
    height: collapsed ? '70px' : 'unset',
    background: selected ? '#F3F3F3' : '#FFFFFF',
    borderRadius: '10px',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: color,
    marginBottom: '22px',
    padding: '0px',
    cursor: 'pointer',
    textAlign: 'left',
    boxShadow: selected ? `inset 0px 0px 0px 2px ${color}` : 'inset 0px 0px 0px 0px, 0px 0px 0px 0px #000000cf',
    '&:hover': {
        boxShadow: selected ? `inset 0px 0px 0px 2px ${color}` : 'inset 0px 0px 0px 0px, 0px 0px 5px 0px #000000cf'
    },
    transition: '300ms, box-shadow 200ms',
    [theme.breakpoints.down('sm')]: {
        width: '272px',
    }
});
const drawerAssetTagStyles = (collapsed, color) => ({
    flexShrink: 0,
    width: collapsed ? '16px' : '35px',
    height: 'auto',
    backgroundColor: color,
    transition: '300ms',
});
const assetBadgeStyles = (collapsed) => ({
    position: 'absolute',
    display: 'flex',
    height: '30px',
    alignItems: 'center',
    top: '50%',
    transform: 'translateY(-50%)',
    left: collapsed ? '2px' : '4px',
    transition: '300ms'
});
const drawerAssetIconWrapperStyles = (collapsed) => ({
    display: 'flex',
    flexShrink: 0,
    marginLeft: collapsed ? '12px' : '5px',
    marginRight: '8px',
    transition: '400ms',
    alignSelf: 'center'
});
const drawerAssetIconStyles = (collapsed, submittableStatus) => ({
    stroke: '#000',
    height: '50px',
    width: '50px',
    marginLeft: collapsed && !submittableStatus ? '-5px' : '0px',
});
const assignmentContentHeaderWrapper = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 20px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    }
};
const drawerAssetDescription = {
    alignSelf: 'center',
    flexShrink: 2,
    padding: '8px 10px 8px 5px',
    fontSize: '13px',
    fontWeight: '700',
    lineHeight: 1.10,
};
const fullScreenButtonStyles = {
    position: 'absolute',
    bottom: 40,
    right: 40,
    height: '44px',
    width: '44px',
    padding: 0,
    background: '#FFF !important',
    boxShadow: '0px 4px 9px 0px #00000025',
    border: '2px solid #DDD',
    transition: '300ms',
    '&:hover': {
        scale: '1.3',
        boxShadow: '0px 4px 15px 1px #00000020'
    },
};
export { assetBadgeStyles, assignmentContainerStyles, assignmentContentHeaderWrapper, assignmentContentWrapper, assignmentDrawerStyles, collapseArrowStyles, drawerAssetDescription, drawerAssetIconStyles, drawerAssetIconWrapperStyles, drawerAssetItemStyles, drawerAssetTagStyles, fullScreenButtonStyles, openNewTabWrapperStyles, pdfViewerStyles, viewerWrapperStyles, };

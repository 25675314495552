import { jsx as _jsx } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { viewerWrapperStyles } from './assignment.styles';
const ImageViewer = ({ asset }) => {
    return (_jsx(Box, { sx: viewerWrapperStyles, children: _jsx(Box, { height: '100%', width: '100%', minHeight: '400px', sx: {
                background: `url(${asset.assetLink}) no-repeat center center`,
                backgroundSize: 'contain',
            } }) }));
};
export default ImageViewer;

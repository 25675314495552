import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Route, Routes, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Account from './components/Account/Account';
import AssignmentContainer from './components/Assignment/AssignmentContainer';
import Auth from './components/Auth/Auth';
import Login from './components/Auth/LoginForm';
import SignUpForm from './components/Auth/SignUpForm';
import SSO from './components/Auth/SSO';
import { useDialog } from './components/Dialog/DialogProvider';
import TimeoutDialog from './components/Dialog/TimeoutDialog';
import { NotFound, WrongLink } from './components/Error';
import InquiryHomepage from './components/Inquiries/InquiryHomepage';
import InquirySelector from './components/Inquiries/InquirySelector';
import ModuleOverview from './components/Module/ModuleOverview';
import ProtectedRoute from './components/Utils/ProtectedRoute';
import { useSessionContext } from './context/SessionContext';
import { MINUTE_IN_MS, SECOND_IN_MS } from './helpers/constants';
import { useLogout } from './hooks/loginHooks';
const excludePaths = new RegExp(/(\/auth\/login)|(\/auth\/signup\/)(.)*/);
const App = () => {
    const logout = useLogout();
    const location = useLocation();
    /**
     * Session Context Setup.
     */
    const [sessionContext, updateSessionContext] = useSessionContext();
    useEffect(() => {
        updateSessionContext({
            ...sessionContext,
            isAuthenticated: localStorage.getItem('AccessToken') !== null,
            redirectPath: `${location.pathname}${location.search}`,
        });
    }, []);
    const setRedirectPath = (path) => {
        updateSessionContext({ ...sessionContext, redirectPath: path });
    };
    /**
     * Dialog Provider Setup.
     * @see src/components/Dialog/DialogProvider.tsx
     */
    const [openDialog, closeDialog, isOpen] = useDialog();
    const onOpenDialog = () => {
        openDialog({
            children: (_jsx(TimeoutDialog, { onClose: () => {
                    reset();
                    start();
                    closeDialog();
                } })),
        });
    };
    /**
     * Idle Timer Setup.
     * Additional functions not used:
     * onActive: Called when the user becomes active again after being idle.
     * onAction: Called when the user performs an action (e.g. mousemove, keydown, etc.)
     * To use the functions above, add them to the useIdleTimer hook below and
     * define them in the component.
     * @see https://idletimer.dev/
     */
    const idleTimeOutLimit = (60 * MINUTE_IN_MS);
    // onIdle is called when the user becomes idle.
    const onIdle = () => {
        if (!isOpen() && !excludePaths.test(location.pathname)) {
            onOpenDialog();
        }
    };
    // onMessage is called when the user receives a message from another tab.
    const onMessage = (data) => {
        switch (data) {
            case 'logout':
                !isLeader() && logout();
                break;
            default:
                break;
        }
    };
    // Functions and Config for useIdleTimer hook.
    const { getIdleTime, // time elapsed after timeout reached
    reset, start, isLeader, message, } = useIdleTimer({
        onIdle,
        onMessage,
        startOnMount: false,
        startManually: true,
        throttle: 500,
        timeout: idleTimeOutLimit,
        crossTab: true,
        leaderElection: true
    });
    useEffect(() => {
        if (!excludePaths.test(location.pathname)) {
            start();
            const interval = setInterval(() => {
                const totalIdle = Math.ceil(getIdleTime() / 1000);
                if (totalIdle >= 10) {
                    logout();
                    message('logout');
                }
            }, 500);
            return () => {
                clearInterval(interval);
            };
        }
    }, [location]);
    // Check LastVisitedOn if it is not recent to logout, otherwise maintain it.
    useEffect(() => {
        if (sessionContext.isAuthenticated) {
            // get LastVisitedOn timestamp from localStorage, should be setup on login
            const lastVisitedOn = localStorage.getItem('LastVisitedOn');
            const isLastVisitedOnFresh = Boolean(lastVisitedOn !== null
                && (Number(lastVisitedOn) + idleTimeOutLimit) > Date.now());
            // Logout if LastVisitedOn is not recent
            if (!isLastVisitedOnFresh) {
                logout();
            }
            // maintain LastVisitedOn every 5 seconds
            const interval = setInterval(() => {
                localStorage.setItem('LastVisitedOn', String(Date.now()));
            }, (5 * SECOND_IN_MS));
            return () => {
                clearInterval(interval);
            };
        }
    }, [location]);
    // Protected Route Setup.
    const defaultProtectedRouteProps = {
        isAuthenticated: !!sessionContext.isAuthenticated,
        authenticationPath: '/auth/login',
        redirectPath: sessionContext.redirectPath,
        setRedirectPath,
    };
    return (_jsxs(Routes, { children: [_jsxs(Route, { path: '/', element: _jsx(ProtectedRoute, { ...defaultProtectedRouteProps }), children: [_jsx(Route, { path: '/', element: _jsx(InquirySelector, {}) }), _jsx(Route, { path: '/inquiries', element: _jsx(InquirySelector, {}) }), _jsx(Route, { path: '/dashboard/:inquiryId', element: _jsx(InquiryHomepage, {}) }), _jsx(Route, { path: '/overview/:inquiryId/:moduleId', element: _jsx(ModuleOverview, {}) }), _jsx(Route, { path: '/assignment/:inquiryId/:moduleId/:assignmentId', element: _jsx(AssignmentContainer, {}) }), _jsx(Route, { path: '/account', element: _jsx(Account, {}) }), _jsx(Route, { path: '*', element: _jsx(NotFound, {}) })] }), _jsxs(Route, { path: '/auth', element: _jsx(Auth, {}), children: [_jsx(Route, { path: 'signup/:studentClassId', element: _jsx(SignUpForm, {}) }), _jsx(Route, { path: 'login', element: _jsx(Login, {}) }), _jsx(Route, { path: '*', element: _jsx(Typography, { align: 'center', padding: '5rem', variant: "h1", children: 'Incorrect Login link.' }) })] }), _jsx(Route, { path: '/sso', element: _jsx(SSO, {}) }), _jsx(Route, { path: '/bad-link', element: _jsx(WrongLink, {}) }), _jsx(Route, { path: '*', element: _jsx(NotFound, {}) })] }));
};
export default App;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { Eye, EyeSlash } from 'phosphor-react';
import * as yup from 'yup';
import ClassLinkIcon from '../../../public/logos/classlink-large.png';
import CleverIcon from '../../../public/logos/clever.png';
import { getSSOAuthRedirect } from '../../helpers/authHelpers';
import { setDocumentTitle } from '../../helpers/documentHelper';
import { useAuthFormikInputFieldBuilder, useLogin } from '../../hooks/loginHooks';
import { IdentityProvider } from '../../typings/authTypings';
import { disclaimerStyles, incorrectCredentialsStyles, loginFormStyles, loginSubmitDivStyles, questionsAboutLoginStyles, ssoButtonIconStyles, ssoButtonStyles, welcomeMessageStyles } from './auth.styles';
import TermsOfService from './TermsOfService';
const QuestionsAboutLogin = () => {
    return (_jsx(Typography, { variant: 'body2', sx: questionsAboutLoginStyles, children: 'If you’re not sure how to log in, talk to your teacher.' }));
};
const ssoButtons = [
    {
        ssoIdentityProvider: IdentityProvider.CLASSLINK,
        label: 'Log in with ClassLink',
        icon: ClassLinkIcon,
        href: getSSOAuthRedirect(IdentityProvider.CLASSLINK),
    },
    {
        ssoIdentityProvider: IdentityProvider.CLEVER,
        label: 'Log in with Clever',
        icon: CleverIcon,
        href: getSSOAuthRedirect(IdentityProvider.CLEVER),
    },
];
const LoginForm = () => {
    const [showPassword, setShowPassword] = useState({ password: false });
    const [failedLogin, setFailedLogin] = useState(false);
    const login = useLogin();
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            username: yup.string().required('Username Required'),
            password: yup.string().required('Password Required'),
        }),
        onSubmit: (formData) => {
            login(formData.username, formData.password, (err) => {
                setFailedLogin(true);
                console.error('Error occurred - ', err);
            });
        },
    });
    const getFirstErrorKey = (object) => {
        const firstErrorKey = Object.keys(object)[0];
        return firstErrorKey ?? '';
    };
    useEffect(() => {
        setDocumentTitle('inquirED student login');
    }, []);
    useEffect(() => {
        if (!formik.isValid && formik.isSubmitting) {
            const firstErrorKey = getFirstErrorKey(formik.errors);
            global.window.document.getElementsByName(firstErrorKey)[0]?.focus();
        }
    }, [formik.isValid, formik.errors, formik.isSubmitting]);
    // Adding a formik field builder to the form significantly
    // reduces cyclomatic complexity
    const textFieldBuilder = useAuthFormikInputFieldBuilder(formik);
    return (_jsxs(Box, { "data-testid": 'LoginForm', component: 'form', onSubmit: formik.handleSubmit, sx: loginFormStyles, children: [_jsx(Typography, { variant: 'h1', sx: welcomeMessageStyles, children: 'Student Login' }), _jsx(Typography, { variant: 'body2', children: '* indicates a required field' }), _jsx(Box, { margin: '10px 0 16px 0', sx: { '@media (max-height: 500px)': { margin: 0 } }, children: textFieldBuilder('username', 'Username', 'text', null) }), _jsx(Box, { marginBottom: '12px', sx: { '@media (max-height: 500px)': { marginBottom: '10px' } }, children: textFieldBuilder('password', 'Password', showPassword.password ? 'text' : 'password', (_jsx(InputAdornment, { position: 'end', sx: { marginRight: '10px' }, children: _jsx(IconButton, { "aria-label": "Toggle Password Visibility", onClick: () => setShowPassword({
                            ...showPassword, password: !showPassword.password
                        }), children: showPassword.password ? _jsx(Eye, {}) : _jsx(EyeSlash, {}) }) })), { FormHelperTextProps: {
                        sx: {
                            position: 'absolute',
                            bottom: '8px',
                            right: '40px',
                        }
                    } }) }), _jsx(Box, { sx: loginSubmitDivStyles, children: _jsx(Button, { type: 'submit', variant: 'contained', disabled: !formik.values.password || !formik.values.username, children: 'Log In' }) }), failedLogin && _jsx(Typography, { sx: incorrectCredentialsStyles, role: 'alert', children: 'Username or password is incorrect. Ask your teacher for help.' }), _jsx(Divider, {}), ssoButtons.map((button, index) => ( //SSO buttons are purposely larger than themed buttons
            _jsx(Button, { "data-testid": `${button.ssoIdentityProvider}-login-button`, variant: 'outlined', fullWidth: true, role: 'link', href: button.href, sx: ssoButtonStyles, startIcon: _jsx("img", { src: button.icon, alt: '', style: { ...ssoButtonIconStyles, position: 'absolute' } }), children: button.label }, index))), _jsxs(Box, { sx: disclaimerStyles, children: [_jsx(QuestionsAboutLogin, {}), _jsx(Box, { padding: '12px 0', borderTop: '1px solid #DDDDDD', marginTop: '20px', children: _jsx(TermsOfService, {}) })] })] }));
};
export default LoginForm;

import theme from '../../theme/customTheme';
export const selectorWrapper = {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '-15px',
    [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'hidden',
        marginLeft: '0rem',
    }
};
export const unitCardWrapper = {
    textDecoration: 'none',
    width: '325px',
    border: '3px solid #474A63',
    borderRadius: '16px',
    margin: '15px',
    overflow: 'hidden',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.down('sm')]: {
        width: '250px',
    },
    [theme.breakpoints.down('xs')]: {
        border: 'none',
        backgroundColor: '#FFF',
        padding: '15px',
        width: '85%',
    },
    transition: 'all 0.3s ease-in-out, boxShadow 200ms',
    '&:hover': {
        boxShadow: '0px 0px 20px -2px #050505bd',
    },
    '&:focus': {
        boxShadow: '0px 0px 20px -2px #050505bd',
        outline: 'thick double'
    },
};
export const unitCardImage = {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
        borderRadius: '13px',
    }
};
export const unitCardInfo = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '12px 16px',
    position: 'relative',
    backgroundColor: '#FFF',
    flex: '1',
    minHeight: '220px',
    [theme.breakpoints.down('xs')]: {
        display: 'none'
    }
};
export const unitCardDescription = (lineClamp) => ({
    overflow: 'hidden',
    fontSize: '15px',
    lineHeight: '24px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: lineClamp,
});
export const unitCardToDoSectionBackground = (unitImageBottom) => ({
    minHeight: '120px',
    padding: '8px',
    display: 'flex',
    borderRadius: '0 0 12px 12px',
    background: `url(${unitImageBottom})`,
    backgroundSize: '100%',
    [theme.breakpoints.down('xs')]: {
        background: 'none',
        minHeight: '0',
        padding: '0',
    }
});
export const unitCardToDoSection = {
    padding: '10px 16px',
    width: '100%',
    borderRadius: '0 0 12px 12px',
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
};
export const inquiryHomepageWrapper = {
    display: 'flex',
    padding: '24px',
    maxWidth: '1440px',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center',
        padding: '24px 10px',
        overflowX: 'hidden'
    }
};
export const inquiryHomepageInfo = {
    width: '30%',
    [theme.breakpoints.down('xs')]: {
        width: '100%',
    }
};
export const inquiryHomepageQuestion = {
    fontSize: '36px',
    fontWeight: '700',
    color: '#474A63',
    lineHeight: '1',
    margin: '20px 0',
    [theme.breakpoints.down('xs')]: {
        padding: '0px 2px',
        fontSize: '24px'
    }
};
export const inquiryHomepageDescription = {
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
        padding: '0px 2px',
    }
};
export const inquiryHomepageModuleSelector = {
    overflowY: 'auto',
    flexGrow: 1,
    paddingLeft: '30px',
    paddingRight: '10px',
    paddingTop: '2px',
    [theme.breakpoints.down('xs')]: {
        flexGrow: 0,
        width: '100%',
        marginTop: '20px',
        padding: '0px 2px',
        overflowY: 'visible',
    }
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { ModuleDataContext } from '../../context/ModuleDataContext';
import { setDocumentTitle } from '../../helpers/documentHelper';
import useAssignmentApi from '../../hooks/assignmentHooks';
import theme from '../../theme/customTheme';
import Feedback from '../Alert/Feedback';
import { assignmentContainerStyles } from './assignment.styles';
import AssignmentContent from './AssignmentContent';
import AssignmentDrawer from './AssignmentDrawer';
import AssignmentVerticalDrawer from './AssignmentVerticalDrawer';
const NoAssignmentContentMessage = () => {
    return (_jsx(Typography, { variant: 'body2', paddingTop: '40px', display: 'flex', justifyContent: 'center', width: '100%', fontStyle: 'italic', children: 'No materials attached' }));
};
const AssignmentContainer = () => {
    const { inquiryId, assignmentId } = useParams();
    const { setModuleNumber } = useContext(ModuleDataContext);
    const [collapsed, setCollapsed] = useState((window.visualViewport?.width ?? 0) < theme.breakpoints.values.md);
    const [assignmentData, setAssignmentData] = useState();
    const { selectedAsset, setSelectedAsset, setAnnotations, setDocumentAndAnnotationsLoaded, submitAssignment, } = useAssignmentApi(assignmentId, inquiryId, (data) => {
        setAssignmentData(data);
        const moduleNumber = data.moduleNumber;
        setModuleNumber(moduleNumber);
    });
    useEffect(() => {
        if (assignmentData?.assignmentContent && selectedAsset) {
            const transformedAssignmentData = { ...assignmentData };
            const selectedAssetIndex = transformedAssignmentData.assignmentContent
                .findIndex((ad) => ad._id === selectedAsset._id);
            transformedAssignmentData.assignmentContent[selectedAssetIndex] = selectedAsset;
            setAssignmentData(transformedAssignmentData);
            window.location.hash = selectedAsset._id;
        }
    }, [selectedAsset]);
    useEffect(() => {
        setDocumentTitle('inquirED student assignment details');
    }, []);
    const handleCollapseDrawer = () => setCollapsed(!collapsed);
    return (_jsxs(Box, { className: 'assignment-container', sx: assignmentContainerStyles, children: [assignmentData
                ? (_jsxs(_Fragment, { children: [_jsx(AssignmentVerticalDrawer, { assignmentData: assignmentData, selectedAsset: selectedAsset, setSelectedAsset: setSelectedAsset }), _jsx(AssignmentDrawer, { assignmentData: assignmentData, selectedAsset: selectedAsset, setSelectedAsset: setSelectedAsset, collapsed: collapsed, handleCollapse: handleCollapseDrawer }), assignmentData.assignmentContent.length === 0 ? (_jsx(NoAssignmentContentMessage, {})) : (_jsx(AssignmentContent, { selectedAsset: selectedAsset, collapsed: collapsed, submitAssignment: submitAssignment, setAnnotations: setAnnotations, setDocumentAndAnnotationsLoaded: setDocumentAndAnnotationsLoaded }))] })) : (_jsx(Box, { children: 'Loading...' })), _jsx(Feedback, {})] }));
};
export default AssignmentContainer;

import theme from '../../theme/customTheme';
export const moduleCardWrapper = {
    flexGrow: 1,
    border: '1px solid #DDDDDD',
    borderRadius: '12px',
    marginBottom: '24px',
    backgroundColor: '#FFF',
    cursor: 'pointer',
    boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.25)',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        background: '#EBEFF3',
    },
    '&:focus-within': {
        backgroundColor: '#EBEFF3',
        outline: '2px solid blue',
    },
};
export const moduleCardToDoSection = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '6px',
    [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: '0px'
    }
};
export const moduleCardToDoItem = {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
        marginTop: '16px'
    }
};
export const moduleHomepageWrapper = {
    padding: '24px',
    maxWidth: '1440px',
    flex: 1,
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
        padding: '0px'
    }
};
export const moduleHomepageBackButton = {
    borderRadius: '24px',
    margin: '18px 0 14px 14px',
    [theme.breakpoints.up('sm')]: {
        display: 'none'
    }
};
export const moduleHomepageAssignmentsWrapper = {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
        borderRadius: '0px',
        border: 'none',
    }
};
export const moduleHomepageHeader = (backgroundImage) => ({
    background: `url(${backgroundImage})`,
    backgroundSize: '100%',
    backgroundPosition: 'center',
    padding: '50px 20px 20px 20px',
    borderRadius: '13px 13px 0px 0px',
    [theme.breakpoints.down('xs')]: {
        padding: '18px 14px 25px 14px',
    }
});
export const moduleHomepageSubtitle = {
    color: '#FFF',
    lineHeight: 1,
};
export const moduleHomepageTitle = {
    color: '#FFF',
    fontSize: '40px',
    fontWeight: '900',
    lineHeight: 1,
    marginTop: '12px',
};
export const accordionGroupStyles = {
    border: '1px solid #DDD',
    borderRadius: '0px 0px 13px 13px',
    '&:focus': {
        outlineOffset: '8px'
    }
};
export const accordionSummaryStyles = {
    borderRadius: '0px',
    height: '64px',
    '&:focus': {
        outline: '2px solid blue',
    }
};
export const accordionDetailsStyles = {
    borderRadius: '0px',
    padding: '0px',
};
export const moduleHomepageAssignmentWrapper = (lastAssignment, lastLesson) => ({
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    padding: '0px 22px 0px 50px',
    margin: '1px, 0',
    borderBottom: lastAssignment && !lastLesson ? 'none' : '1px solid #DDD',
    borderRadius: lastAssignment && lastLesson ? '0px 0px 13px 13px' : '0px',
    cursor: 'pointer',
    textDecorationColor: '#323232    ',
    [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        padding: '20px 30px'
    },
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        transition: 'all 0.3s ease-in-out',
        textDecorationThickness: '2px',
        background: '#EBEFF3',
        'p:first-of-type': {
            textDecoration: 'underline',
        }
    },
    '&:focus': {
        textDecorationThickness: '2px',
        margin: '0px, 0',
        background: '#EBEFF3',
        paddingTop: '0px',
        paddingBottom: '0px',
        outline: '2px solid blue',
    },
});
export const moduleHomepageAssignmentTitle = {
    width: '45%',
    [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: '10px 0'
    }
};
export const moduleHomepageAssignmentDueDate = (dueDateExists) => ({
    width: '25%',
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
        display: dueDateExists ? 'flex' : 'none',
        width: '100%',
        justifyContent: 'flex-start',
        margin: '10px 0'
    }
});
export const moduleHomepageAssignmentIcons = {
    width: '30%',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
        width: '100%',
        justifyContent: 'flex-start',
        margin: '10px 0 10px -.5rem'
    }
};
export const moduleHomepageNoAssignments = {
    color: '#58606A',
    background: '#FFF',
    padding: '25px',
};
export const statusTypographyStyles = {
    fontWeight: 500,
    fontSize: '14px',
    color: theme.palette.primary.main
};

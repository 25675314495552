import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState } from 'react';
export const ModuleDataContext = createContext({
    moduleNumber: '',
    setModuleNumber: () => { },
});
export const ModuleDataContextProvider = ({ children }) => {
    const [moduleNumber, setModuleNumber] = useState('');
    const defaultContext = {
        moduleNumber,
        setModuleNumber
    };
    return (_jsx(ModuleDataContext.Provider, { value: defaultContext, children: children }));
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { Eye, EyeSlash } from 'phosphor-react';
import * as yup from 'yup';
import { FeedbackContext } from '../../context/FeedbackContext';
import { useSessionContext } from '../../context/SessionContext';
import { setDocumentTitle } from '../../helpers/documentHelper';
import { useAccountUpdate } from '../../hooks/accountHooks';
import { useAuthFormikInputFieldBuilderHorizontal } from '../../hooks/loginHooks';
import theme from '../../theme/customTheme';
import Feedback from '../Alert/Feedback';
import AccountSideNav from './SideNav';
const Account = () => {
    const [showPassword, setShowPassword] = useState({ password: false, confirmPassword: false });
    const { updateAccount, changePassword } = useAccountUpdate();
    const [sessionContext, updateSessionContext] = useSessionContext();
    const { setFeedback } = useContext(FeedbackContext);
    const isDirty = (firstName, lastName) => {
        return firstName !== sessionContext.user.firstName
            || lastName !== sessionContext.user.lastName;
    };
    const formik = useFormik({
        initialValues: {
            firstName: sessionContext.user.firstName,
            lastName: sessionContext.user.lastName,
            username: sessionContext.user.username,
            teacher: sessionContext.user.teacher,
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            firstName: yup.string().matches(/^[A-Za-z '-]+$/i, 'First Name invalid format').required('First Name Required'),
            lastName: yup.string().matches(/[A-Za-z '-]+$/i, 'Last Name invalid format').required('Last Name Required'),
            currentPassword: yup.string()
                .test('current-password', 'Must include current password', (value) => {
                if (String(formik.values.newPassword) === '')
                    return true;
                return Boolean(value);
            }),
            newPassword: yup.string().min(6, 'Password must be at least 6 characters')
                .when('currentPassword', {
                is: (value) => !!value,
                then: yup.string().required('Must provide new password')
            }),
            confirmNewPassword: yup.string()
                .test('passwords-match', 'Passwords must match', (value) => {
                return String(formik.values.newPassword) === String(value ?? '');
            })
                .when('newPassword', {
                is: (value) => !!value,
                then: yup.string().required('Passwords must match')
            }),
        }),
        onSubmit: (formData) => {
            const updater = async () => {
                if (isDirty(formData.firstName, formData.lastName)) {
                    updateAccount({
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                    }).then(() => {
                        updateSessionContext({
                            ...sessionContext,
                            user: {
                                ...sessionContext.user,
                                firstName: formData.firstName,
                                lastName: formData.lastName,
                            },
                        });
                        setFeedback({
                            message: 'Account updated!',
                            open: true,
                            severity: 'success'
                        });
                    }).catch((e) => {
                        console.error(e);
                        setFeedback({
                            message: 'Could not update account',
                            open: true,
                            severity: 'error'
                        });
                    });
                }
                if (!!formData.newPassword && !!formData.currentPassword) {
                    changePassword({
                        currentPassword: formData.currentPassword,
                        newPassword: formData.newPassword,
                    }).then(() => {
                        updateSessionContext({
                            ...sessionContext,
                            user: {
                                ...sessionContext.user,
                                firstName: formData.firstName,
                                lastName: formData.lastName,
                            },
                        });
                        formik.setFieldValue('currentPassword', '');
                        formik.setFieldValue('newPassword', '');
                        formik.setFieldValue('confirmNewPassword', '');
                        setFeedback({
                            message: 'Password updated!',
                            open: true,
                            severity: 'success'
                        });
                    }).catch((e) => {
                        console.error(e);
                        setFeedback({
                            message: 'Could not update password',
                            open: true,
                            severity: 'error'
                        });
                    });
                }
            };
            updater();
        }
    });
    const textFieldBuilder = useAuthFormikInputFieldBuilderHorizontal(formik);
    useEffect(() => {
        setDocumentTitle('inquirED student account');
    }, []);
    return (_jsxs(Box, { display: 'flex', overflow: 'auto', width: '100vw', marginLeft: 'calc(50% - 50vw)', children: [_jsx(Box, { width: '12.5rem', borderRight: '1px solid #DDD', children: _jsx(AccountSideNav, {}) }), _jsxs(Box, { role: 'main', tabIndex: 0, flexGrow: 1, component: 'form', onSubmit: formik.handleSubmit, children: [_jsxs(Box, { display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #DDD', sx: { [theme.breakpoints.up('lg')]: { width: '60%' } }, padding: '28px 70px', children: [_jsx(Typography, { component: 'h1', variant: 'h3', children: `${sessionContext.user.firstName} ${sessionContext.user.lastName}` }), _jsx(Button, { variant: 'contained', type: 'submit', sx: {
                                    '&:focus': {
                                        outline: '2px solid blue'
                                    }
                                }, children: 'Save' })] }), _jsxs(Box, { sx: { [theme.breakpoints.up('lg')]: { width: '60%' } }, margin: '30px 70px', children: [_jsxs(Box, { borderBottom: '1px solid #DDD', children: [_jsx(Typography, { marginBottom: '1rem', variant: 'h3', component: 'h2', children: 'Personal Information' }), _jsxs(Box, { display: 'flex', flexDirection: 'column', paddingLeft: '24px', children: [textFieldBuilder('firstName', 'First Name', 'text', null, null), textFieldBuilder('lastName', 'Last Name', 'text', null, null)] })] }), _jsxs(Box, { padding: '20px 0', borderBottom: '1px solid #DDD', children: [_jsx(Typography, { marginBottom: '1rem', variant: 'h3', component: 'h2', children: 'Account Information' }), _jsxs(Box, { display: 'flex', flexDirection: 'column', paddingLeft: '24px', children: [textFieldBuilder('username', 'Username', 'text', 'Usernames cannot be changed once created.', null, true), textFieldBuilder('teacher', 'Teacher', 'text', null, null, true)] })] }), _jsxs(Box, { padding: '20px 0', borderBottom: '1px solid #DDD', children: [_jsx(Typography, { marginBottom: '1rem', variant: 'h3', component: 'h2', children: 'Change Password' }), _jsxs(Box, { display: 'flex', flexDirection: 'column', paddingLeft: '24px', children: [textFieldBuilder('currentPassword', 'Current Password', showPassword.password ? 'text' : 'password', null, (_jsx(InputAdornment, { position: 'end', sx: { marginRight: '10px' }, children: _jsx(IconButton, { "aria-label": "Toggle Current Password Visibility", onClick: () => setShowPassword({
                                                        ...showPassword, password: !showPassword.password
                                                    }), children: showPassword.password ? _jsx(Eye, {}) : _jsx(EyeSlash, {}) }) }))), textFieldBuilder('newPassword', 'New Password', showPassword.confirmPassword ? 'text' : 'password', null, (_jsx(InputAdornment, { position: 'end', sx: { marginRight: '10px' }, children: _jsx(IconButton, { "aria-label": "Toggle New Password Visibility", onClick: () => setShowPassword({
                                                        ...showPassword,
                                                        confirmPassword: !showPassword.confirmPassword
                                                    }), children: showPassword.confirmPassword ? _jsx(Eye, {}) : _jsx(EyeSlash, {}) }) }))), textFieldBuilder('confirmNewPassword', 'Confirm New Password', showPassword.confirmPassword ? 'text' : 'password', null, (_jsx(InputAdornment, { position: 'end', sx: { marginRight: '10px' }, children: _jsx(IconButton, { "aria-label": "Toggle New Password Visibility", onClick: () => setShowPassword({
                                                        ...showPassword,
                                                        confirmPassword: !showPassword.confirmPassword
                                                    }), children: showPassword.confirmPassword ? _jsx(Eye, {}) : _jsx(EyeSlash, {}) }) })))] })] })] })] }), _jsx(Feedback, {})] }));
};
export default Account;

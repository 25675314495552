import ReactDOMServer from 'react-dom/server';
import WebViewer from '@pdftron/pdfjs-express';
import Speaker from '../../public/icons/pdfToolbarIcons/ic_speaker.svg';
import NewTab from '../../public/icons/pdfToolbarIcons/new_tab.svg';
const speakerString = ReactDOMServer.renderToString(Speaker());
const newTabString = ReactDOMServer.renderToString(NewTab());
/**
 * initializes WebViewer
 * @param viewer viewer ref
 * @param setAnnotations state setter for annotations
 */
const buildWebViewer = (viewer, setAnnotations, previewLink, setInstance) => {
    WebViewer({
        path: '/public/webviewer/lib',
        licenseKey: process.env.PDFJS_EXPRESS_KEY,
        annotationUser: 'student',
        disabledElements: [
            'header',
            'toolsHeader',
        ],
    }, viewer.current).then(async (instance) => {
        const { documentViewer, annotationManager, Annotations } = instance.Core;
        const toolModeMap = documentViewer.getToolModeMap();
        buildHeaderItems(instance, documentViewer, previewLink);
        initializeTools(instance);
        documentViewer.getTool('AnnotationCreateFreeHand').setStyles({
            StrokeColor: new Annotations.Color(0, 0, 0),
            StrokeThickness: 3
        });
        documentViewer.getTool('AnnotationCreateRectangle').setStyles({
            StrokeColor: new Annotations.Color(0, 0, 0),
            StrokeThickness: 3
        });
        documentViewer.getTool('AnnotationCreateFreeText').setStyles({
            TextColor: new Annotations.Color(0, 0, 0),
            FontSize: '18pt',
            StrokeColor: new Annotations.Color(0, 0, 0),
            StrokeThickness: 0
        });
        instance.UI.disableElements([
            'annotationCommentButton',
            'linkButton',
            'opacitySlider',
            'strokeThicknessSlider',
            'notesPanel',
            'leftPanelTabs',
            'richTextPopup',
            'thumbnailsSizeSlider',
            'richTextStrikeoutButton',
            'freeHandHighlightToolButton'
        ]);
        instance.UI.setToolbarGroup('toolbarGroup-Annotate');
        documentViewer.setToolMode(toolModeMap.Pan);
        instance.UI.loadDocument(previewLink, { filename: 'myfile.pdf' });
        instance.UI.enableElements(['header']);
        instance.UI.setColorPalette([
            '#F1A099', '#FFC67B', '#FFE6A2', '#80E5B1', '#92E8E8', '#A6A1E6', '#E2A1E6', '#E44234',
            '#FF8D00', '#FFCD45', '#00CC63', '#25D2D1', '#4E7DE9', '#C544CE', '#88271F', '#B54800',
            '#F69A00', '#007A3B', '#167E7D', '#4A4B8B', '#76287B', '#FFFFFF', '#CDCDCC', '#9C9C9C',
            '#696969', '#272727', '#000000', '#7D592E', '#F9E4CC', '#D6AF81',
        ]);
        instance.hotkeys.off('q');
        annotationManager.addEventListener('annotationChanged', async () => {
            const annotations = await annotationManager.exportAnnotations();
            setAnnotations(annotations);
        });
        setInstance(instance);
    });
};
/**
 * Creates header item toolbar for WebViewer
 * @param instance viewer ref
 * @param docViewer instance core tool to access doc
 */
const buildHeaderItems = (instance, docViewer, previewLink) => {
    instance.UI.setHeaderItems((header) => {
        const oldHeader = header.getItems();
        const newHeader = [
            // left panel button
            oldHeader[0],
            // zoom buttons
            {
                ...oldHeader[3],
                hiddenOnMobileDevice: false
            },
            {
                type: 'divider',
            },
            // pan button
            {
                ...oldHeader[5],
                hidden: false,
            },
            // mobile select button
            {
                ...oldHeader[6],
                hidden: false,
            },
            // select button
            {
                ...oldHeader[7],
                hidden: false,
            },
            {
                type: 'divider',
            },
            {
                type: 'actionButton',
                style: { 'marginLeft': '0px' },
                dataElement: 'undoButton',
                title: 'Undo',
                img: 'icon-operation-undo',
                onClick: () => {
                    docViewer.getAnnotationHistoryManager().undo();
                },
                isNotClickableSelector: () => !docViewer.getAnnotationHistoryManager().canUndo()
            },
            {
                type: 'actionButton',
                dataElement: 'redoButton',
                title: 'Redo',
                img: 'icon-operation-redo',
                onClick: () => {
                    docViewer.getAnnotationHistoryManager().redo();
                },
                isNotClickableSelector: () => !docViewer.getAnnotationHistoryManager().canRedo()
            },
            buildToolGroupButton('highlightTools', 'highlightGroupButton', 'Highlight'),
            buildToolGroupButton('underlineTools', 'underlineGroupButton', 'Underline'),
            buildToolGroupButton('freeTextTools', 'freeTextGroupButton', 'Text'),
            buildToolGroupButton('rectangleTools', 'rectangleGroupButton', 'Rectangle'),
            buildToolGroupButton('freeHandTools', 'freeHandGroupButton', 'Draw'),
            {
                type: 'toolButton',
                toolName: 'AnnotationEraserTool'
            },
            {
                type: 'actionButton',
                img: speakerString,
                title: 'Text to Speech',
                onClick: () => {
                    // docViewer selected text
                    const page = docViewer.getCurrentPage();
                    const text = docViewer.getSelectedText(page);
                    // new SpeechSynthesisUtterance object
                    const utterance = new SpeechSynthesisUtterance();
                    utterance.lang = 'en-US';
                    utterance.text = text;
                    utterance.volume = 0.5;
                    // speak
                    if (text) {
                        window.speechSynthesis.speak(utterance);
                    }
                }
            },
            {
                type: 'spacer',
            },
            {
                type: 'actionButton',
                img: newTabString,
                title: 'Open Doc',
                dataElement: 'popout',
                onClick: () => window.open(previewLink, '_blank')
            }
        ];
        // add the line tool to the top header
        header.getHeader('default').update(newHeader);
    });
};
/**
 * builds tool group button objects for header
 * @param toolGroup
 * @param dataElement
 * @param title
 * @returns button object
 */
const buildToolGroupButton = (toolGroup, dataElement, title) => ({
    type: 'toolGroupButton',
    toolGroup,
    dataElement,
    title,
    hidden: false,
});
/**
 * Rebuild the header items so the "Open in New Tab" functions.
 * @param instance The WebViewer Instance
 * @param nextLink Preview Link for the current Asset
 */
export const rebuildHeaderItems = (instance, nextLink) => {
    if (instance) {
        instance.UI.setHeaderItems((header) => {
            const oldHeader = header.getItems();
            const newHeader = [...oldHeader];
            const oldNewTabActionButton = newHeader.pop();
            const newNewTabActionButton = {
                ...oldNewTabActionButton,
                onClick: () => window.open(nextLink, '_blank')
            };
            newHeader.push(newNewTabActionButton);
            header.getHeader('default').update(newHeader);
        });
    }
};
/**
 * Removes and sets the "documentReady" listener. Useful for setting and resetting
 * the annotations between document loading.
 * @param instance The WebViewer Instance
 * @param listener The new event listener to attach
 * @remarks
 * The built-in "removeEventListener" is broken. To fix it, we manually scan
 * for the eventListener and remove it. we use the `.annots` as a namespace to easily find it.
 * To see more, investigate the `instance.Core.documentViewer._events` object
 * in DevTools
 *
 * Our use of it will only allow one with the namespace. Should we need to extend it, we should
 * add another namespaced event. e.g. `documentReady.newEvent`
 */
export const resetReadyListener = async (instance, listener) => {
    if (instance) {
        if (instance.Core.documentViewer._events.documentReady) {
            const listenerIndex = instance.Core.documentViewer._events.documentReady.findIndex((list) => list.RV === 'annots');
            instance.Core.documentViewer._events.documentReady.splice(listenerIndex, 1);
        }
        return instance.Core.documentViewer.addEventListener('documentReady.annots', listener);
    }
};
/**
 * Removes or Adds annotations in between document loading.
 * @param instance The WebViewer Instance
 * @param selectedAsset The new asset to load annotations onto or remove from
 */
export const annotationsLoader = async (instance, selectedAsset) => {
    if (instance) {
        if (selectedAsset.submissions[0]) {
            const xfpdfString = selectedAsset.submissions[0].submissionData;
            await instance.Core.annotationManager.importAnnotations(xfpdfString);
        }
        else {
            // remove annotations
            const previousAnnotations = instance.Core.annotationManager.getAnnotationsList();
            instance.Core.annotationManager.deleteAnnotations(previousAnnotations);
        }
    }
};
/**
 * Loads a new asset into the Document Viewer
 * @param instance The WebViewer Instance
 * @param asset The new asset to load
 */
export const loadNewDocument = async (instance, asset, callback) => {
    if (instance) {
        let nextLink = `${process.env.API_URL}/content/asset/${asset.assetId}/view/${asset.activeContentId}`;
        if (asset.custom) {
            nextLink = asset.assetLink;
        }
        rebuildHeaderItems(instance, nextLink);
        resetReadyListener(instance, () => annotationsLoader(instance, asset).then(callback));
        instance.UI.closeDocument()
            .then(() => {
            instance.UI.loadDocument(nextLink);
        }).catch(console.error);
    }
};
const initializeTools = (instance) => {
    const { Tools } = instance.Core;
    instance.UI.enableTools();
    instance.UI.disableTools([
        Tools.ToolNames.ARROW,
        Tools.ToolNames.CALLOUT,
        Tools.ToolNames.REDACTION,
        Tools.ToolNames.SIG_FORM_FIELD,
        Tools.ToolNames.SQUIGGLY,
        Tools.ToolNames.STAMP,
        Tools.ToolNames.STICKY,
        Tools.ToolNames.LINE,
    ]);
    instance.hotkeys.off('q');
};
const disableToolbarAnnotations = (instance) => {
    instance.UI.disableTools();
    // clear annotation history so no previous changes can be undone
    instance.Core.documentViewer.getAnnotationHistoryManager().clear();
    instance.Core.annotationManager.setPermissionCheckCallback(() => false);
};
const enableToolbarAnnotations = (instance) => {
    initializeTools(instance);
    instance.Core.annotationManager.setPermissionCheckCallback((author) => author === 'student');
};
export const handleTools = (instance, disableTools) => {
    if (disableTools) {
        disableToolbarAnnotations(instance);
    }
    else {
        enableToolbarAnnotations(instance);
    }
};
/**
 * Accesses the iFrame DOM directly as suggested by PDFJS documentation:
 * https://pdfjs.express/documentation/ui-customization/customizing-styles#accessing-dom-directly
 * selects toolbar and toolbar elements
 * sets styles to each element to acheive reflow design at viewports lower than 770px
 * or
 * resets styles of each element to the original computed styles
 * and
 * pushes/pops fullscreen button into/from toolbar based on matches param
 * @param instance The WebViewer Instance
 * @param matches boolean determined by viewport width media query matching 770px or smaller
 */
export const overrideOrRevertHeaderStyles = (instance, matches) => {
    const iframeDoc = instance.UI.iframeWindow.document;
    const header = iframeDoc.querySelector('[data-element="header"]');
    const headerItems = iframeDoc.getElementsByClassName('HeaderItems')[0];
    const buttons = iframeDoc.getElementsByClassName('Button');
    const toolGroup = iframeDoc.getElementsByClassName('tool-group-buttons-container')[0];
    const toolGroupScroll = iframeDoc.getElementsByClassName('tool-group-buttons-scroll')[0];
    const toolGroupButtons = iframeDoc.getElementsByClassName('tool-group-button');
    const dividers = iframeDoc.getElementsByClassName('divider');
    if (matches) {
        // move fullscreen button to toolbar:
        instance.UI.setHeaderItems((header) => {
            header.getHeader().push({
                img: 'icon-header-full-screen',
                index: -1,
                type: 'actionButton',
                element: 'fullScreenButton',
                onClick: () => {
                    instance.UI.toggleFullScreen();
                }
            });
        });
        // get iframe DOM:
        iframeDoc.getElementsByClassName('spacer')[0].style.display = 'none';
        // set header styles:
        header.style.height = '70px';
        toolGroupScroll.style.height = 'unset';
        headerItems.style.flexWrap = 'wrap';
        toolGroup.style = 'height:unset;';
        [...buttons].forEach(button => button.style.margin = 0);
        [...toolGroupButtons].forEach(el => el.style.margin = 0);
        [...dividers].forEach(el => el.style.margin = 0);
    }
    else {
        instance.UI.setHeaderItems((header) => {
            const fullScreenToggleButton = header.getItems()
                .find((tool) => tool.element === 'fullScreenButton');
            if (fullScreenToggleButton) {
                header.getHeader().pop();
            }
        });
        const originalHeaderStyles = window.getComputedStyle(header);
        const originalHeaderItemsStyles = window.getComputedStyle(headerItems);
        const originalToolGroupStyles = window.getComputedStyle(toolGroup);
        const originalToolGroupScrollStyles = window.getComputedStyle(toolGroupScroll);
        const originalButtonStyles = window.getComputedStyle(buttons[0]);
        const originalToolGroupButtonStyles = window.getComputedStyle(toolGroupButtons[0]);
        iframeDoc.getElementsByClassName('spacer')[0].style.display = 'block';
        header.style = originalHeaderStyles;
        headerItems.style = originalHeaderItemsStyles;
        toolGroup.style = originalToolGroupStyles;
        toolGroupScroll.style = originalToolGroupScrollStyles;
        [...buttons].forEach(button => button.style = originalButtonStyles);
        [...toolGroupButtons]
            .forEach(button => button.style = originalToolGroupButtonStyles);
    }
};
export default buildWebViewer;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { setDocumentTitle } from '../../helpers/documentHelper';
import { bodyStyles, contentContainerStyles, footerStyles, headingStyles, } from './warningsStyles';
const FullScreenErrorDialog = ({ documentTitle, heading, body, footer, graphic, primaryButton: primaryAction, secondaryButton: secondaryAction, }) => {
    useEffect(() => {
        setDocumentTitle(documentTitle);
    }, []);
    return (_jsxs(Dialog, { fullScreen: true, open: true, children: [_jsx(DialogTitle, {}), _jsxs(DialogContent, { sx: contentContainerStyles, children: [graphic && _jsx(Box, { "aria-hidden": 'true', children: graphic }), _jsx(Typography, { variant: 'h1', sx: headingStyles, children: heading }), _jsx(Typography, { variant: 'body2', sx: bodyStyles, children: body }), primaryAction, secondaryAction, footer
                        ? _jsx(Typography, { variant: 'body2', component: 'p', sx: footerStyles, children: footer })
                        : null] })] }));
};
export default FullScreenErrorDialog;

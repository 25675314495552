import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import ArrowLeft from '../../../public/icons/Arrow_left.svg';
import { assignmentDrawerStyles, collapseArrowStyles } from './assignment.styles';
import DrawerAssetItem from './DrawerAssetItem';
const AssignmentDrawer = ({ assignmentData, selectedAsset, setSelectedAsset, collapsed, handleCollapse, }) => {
    return (_jsxs(Box, { role: 'navigation', className: 'assignment-drawer', sx: assignmentDrawerStyles(collapsed), children: [_jsx(Typography, { variant: 'body2', position: 'absolute', sx: {
                    top: '16px',
                    left: collapsed ? '265px' : '255px',
                }, children: 'Details' }), _jsx(Tooltip, { title: collapsed ? 'Open panel' : 'Collapse panel', enterDelay: 400, arrow: true, enterNextDelay: 300, children: _jsx(IconButton, { className: 'collapse-arrow', onClick: handleCollapse, sx: collapseArrowStyles(collapsed), children: _jsx(ArrowLeft, {}) }) }), _jsx(Collapse, { in: !collapsed, timeout: 300, easing: 'ease-in-out', children: _jsxs(Box, { className: 'assignment-details', marginBottom: '20px', children: [_jsx(Typography, { component: 'p', variant: 'h4', children: assignmentData.lessonNumber }), _jsx(Typography, { component: 'h1', variant: 'h3', children: assignmentData.assignmentTitle }), _jsxs(Typography, { component: 'p', variant: 'subtitle2', marginTop: '8px', children: ['Published on', ' ', dayjs(assignmentData.publishedOn).format('MMM D, YYYY [at] h:mmA')] }), assignmentData.dueDate && (_jsxs(Typography, { component: 'p', variant: 'h4', marginTop: '16px', children: ['Due on', ' ', _jsx(Typography, { variant: 'body2', fontSize: '15px', component: 'span', children: dayjs(assignmentData.dueDate).format('dddd, MMM D [at] h:mmA') })] })), _jsxs(Box, { marginTop: '14px', children: [_jsx(Typography, { component: 'h2', variant: 'h4', children: 'Directions' }), _jsx(Typography, { variant: 'body2', whiteSpace: 'pre-wrap', children: assignmentData.directions })] })] }) }), _jsx(Box, { className: 'assignment-asset-list', sx: { marginLeft: collapsed ? '237px' : '-5px', transition: '400ms cubic-bezier(0.52, 0.28, 0.32, 1.01)' }, children: assignmentData.assignmentContent.map((asset, index) => (_jsx(DrawerAssetItem, { asset: asset, collapsed: collapsed, selectedAsset: selectedAsset, handleSelectAsset: () => setSelectedAsset(asset) }, index))) })] }));
};
export default AssignmentDrawer;

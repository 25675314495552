import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DoneIcon from '../../../public/icons/homepageIcons/Icon_Done.svg';
import RevisitIcon from '../../../public/icons/homepageIcons/Icon_Revisit.svg';
import ToSubmitIcon from '../../../public/icons/homepageIcons/Icon_ToSubmit.svg';
const ModuleAssignmentStatus = ({ assets }) => {
    const RenderTag = ({ isVisible, icon, text }) => {
        if (assets.length > 0 && isVisible) {
            return (_jsxs(Box, { display: 'flex', alignItems: 'center', sx: { margin: '0rem 8px', width: '50%', }, children: [_jsx(Box, { display: 'flex', justifyContent: 'center', width: "30px", children: icon }), _jsx(Typography, { marginLeft: '0.2rem', children: text })] }));
        }
        return null;
    };
    const isAssetVisible = useCallback((status) => {
        return assets.some((asset) => asset.status === status);
    }, [assets]);
    const getProgressItemCount = useCallback((status) => {
        let count = 0;
        assets.forEach((asset) => {
            if (asset.status === status) {
                count += asset.count;
            }
        });
        return `${count} Item${count > 1 ? 's' : ''}`;
    }, [assets]);
    const iconStyle = {
        width: '2rem'
    };
    return (_jsxs(_Fragment, { children: [_jsx(RenderTag, { isVisible: isAssetVisible('revisit'), icon: _jsx(RevisitIcon, { style: iconStyle }), text: `Revisit ${getProgressItemCount('revisit')}` }), _jsx(RenderTag, { isVisible: isAssetVisible('toSubmit'), icon: _jsx(ToSubmitIcon, { style: iconStyle }), text: `Submit ${getProgressItemCount('toSubmit')}` }), _jsx(RenderTag, { isVisible: assets.every((asset) => asset.status === 'done'), icon: _jsx(DoneIcon, { style: iconStyle }), text: 'Done' })] }));
};
export default ModuleAssignmentStatus;

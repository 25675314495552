import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { serializePlateObject } from '../../services/plate/HTMLserializer';
import { unitCardDescription, unitCardImage, unitCardInfo, unitCardToDoSectionBackground, unitCardWrapper, } from './inquiries.styles';
import StatusIcons from './InquiryStatusIcons';
const UnitCard = ({ unit }) => {
    //Dynamic line clamp based on the length of the inquiry question with a minumum value
    const descriptionLineClamp = Math.max(5, 8 - Math.ceil(unit.inquiryQuestion.length / 35));
    return (_jsxs(Box, { component: 'a', href: `/dashboard/${unit._id}`, sx: unitCardWrapper, children: [_jsx(Box, { children: _jsx(Box, { component: 'img', src: unit.images.title, sx: unitCardImage, alt: `Inquiry title ${unit.title}` }) }), _jsxs(Box, { sx: unitCardInfo, children: [_jsx(Typography, { component: 'h2', variant: 'h3', sx: { marginBottom: '12px' }, children: unit.inquiryQuestion }), _jsx(Typography, { component: 'div', sx: unitCardDescription(descriptionLineClamp), children: serializePlateObject(unit.description.body) })] }), _jsx(Box, { sx: unitCardToDoSectionBackground(unit.images.overlay), children: _jsx(StatusIcons, { statusItems: unit.statusItems }) })] }));
};
export default UnitCard;

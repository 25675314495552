import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import BlueOvalBackground from './BlueOvalBackground';
import { graphicForegroundStyles } from './warningsStyles';
import Whoops from '/public/icons/Whoops.svg';
const WhoopsGraphic = () => {
    return (_jsx(BlueOvalBackground, { children: _jsx(Box, { sx: {
                ...graphicForegroundStyles,
                bottom: '0',
                left: '-6px',
            }, children: _jsx(Whoops, {}) }) }));
};
export default WhoopsGraphic;

export const initialSession = {
    redirectPath: '/inquiries',
    isAuthenticated: (window.localStorage.getItem('AccessToken') !== null),
    user: {
        _id: '',
        firstName: '',
        lastName: '',
        username: '',
        teacher: '',
        lastInquiryVisited: '',
        studentClass: {},
    },
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, DialogContent, DialogTitle } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Check, X } from 'phosphor-react';
import { useLogout } from '../../hooks/loginHooks';
const IdleButton = ({ text, onClick, icon, sx }) => {
    return (_jsx(_Fragment, { children: _jsx(Button, { onClick: onClick, sx: {
                fontWeight: 500,
                justifyContent: 'flex-start',
                ...sx,
            }, fullWidth: true, variant: 'outlined', startIcon: icon, children: text }) }));
};
export default function Content({ onClose }) {
    const logout = useLogout();
    return (_jsxs(Box, { width: '400px', children: [_jsx(DialogTitle, { component: 'h3', children: _jsx(Typography, { children: 'Are you still here?' }) }), _jsxs(DialogContent, { dividers: true, children: [_jsx(IdleButton, { text: 'Yes, keep me logged in', icon: _jsx(Check, { size: '30px', color: '#61BA15' }), onClick: onClose, sx: { marginBottom: '24px' } }), _jsx(IdleButton, { text: 'No, log me out', onClick: () => {
                            logout();
                            onClose();
                        }, icon: _jsx(X, { size: '30px', color: '#BD3D44' }) })] })] }));
}

import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { Snackbar } from '@mui/material';
import { FeedbackContext } from '../../context/FeedbackContext';
import { StyledAlert } from './StyledAlert';
const Feedback = () => {
    const { feedback, setFeedback } = useContext(FeedbackContext);
    const handleClose = () => {
        setFeedback({ ...feedback, open: false });
    };
    return (_jsx(Snackbar, { anchorOrigin: { vertical: 'bottom', horizontal: 'left' }, open: feedback.open, autoHideDuration: feedback.disableAutoHide ? null : 5000, onClose: handleClose, children: StyledAlert({ severity: feedback.severity, message: feedback.message, handleClose }) }));
};
export default Feedback;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import uuid from 'react-uuid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RevisitIcon from '../../../public/icons/homepageIcons/Icon_Revisit.svg';
import ToSubmitIcon from '../../../public/icons/homepageIcons/Icon_ToSubmit.svg';
import { moduleCardToDoItem, moduleCardToDoSection, statusTypographyStyles } from './module.styles';
const SingleStatusIcon = ({ status, count }) => {
    const hasStatus = (count > 0);
    // No Statuses to show;
    if (!hasStatus) {
        return (null);
    }
    const displayText = `${status} ${count} item${count > 1 ? 's' : ''}`;
    // Not very extensible, but since there's only TWO forms
    // a Map seemed less overhead than a switch
    const iconMap = new Map([
        ['Revisit', _jsx(RevisitIcon, { style: { width: '2rem' } }, `revisit-${uuid()}`)],
        ['Submit', _jsx(ToSubmitIcon, { style: { width: '2rem' } }, `submit-${uuid()}`)],
    ]);
    return (_jsxs(Box, { sx: moduleCardToDoItem, marginRight: '24px', children: [iconMap.get(status) ?? null, _jsx(Typography, { sx: statusTypographyStyles, children: displayText })] }));
};
const StatusIcons = ({ statusItems }) => {
    const hasApplicableStatuses = (statusItems.revisit > 0 || statusItems.toSubmit > 0);
    // No Statuses to show;
    if (!hasApplicableStatuses) {
        return (null);
    }
    return (_jsxs(Box, { sx: moduleCardToDoSection, children: [_jsx(SingleStatusIcon, { status: "Revisit", count: statusItems.revisit ?? 0 }), _jsx(SingleStatusIcon, { status: "Submit", count: statusItems.toSubmit ?? 0 })] }));
};
export default StatusIcons;

import { jsx as _jsx } from "react/jsx-runtime";
import { Alert, Box, IconButton } from '@mui/material';
import { Check, Info, Warning, X } from 'phosphor-react';
const getBackgroundColor = (severity) => {
    switch (severity) {
        case 'error':
            return '#fc4e50';
        case 'success':
            return '#4ccc62';
        case 'info':
            return '#2f80ed';
        case 'warning':
            return '#fbc522';
        default:
            return '#2f80ed';
    }
};
const alertIconStyles = {
    height: 48,
    width: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    borderRadius: '4px 0px 0px 4px'
};
const getIconFromSeverity = (severity) => {
    switch (severity) {
        case 'error':
            return _jsx(X, { size: 24 });
        case 'warning':
            return _jsx(Warning, { size: 24 });
        case 'success':
            return _jsx(Check, { size: 24 });
        case 'info':
            return _jsx(Info, { size: 24 });
        default:
            return _jsx(Info, { size: 24 });
    }
};
const AlertIcon = ({ severity }) => (_jsx(Box, { sx: { ...alertIconStyles, backgroundColor: getBackgroundColor(severity) }, children: getIconFromSeverity(severity) }));
export const StyledAlert = ({ severity, message, handleClose }) => {
    const action = (_jsx(IconButton, { size: "small", "aria-label": "close", sx: { color: '#58606a !important', marginRight: '16px', height: 'unset' }, onClick: handleClose, children: _jsx(X, { size: 16 }) }));
    return (_jsx(Alert, { action: action, severity: severity, icon: _jsx(AlertIcon, { severity: severity }), children: message }));
};

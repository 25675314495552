import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Check, X } from 'phosphor-react';
const SubmissionConfirmationDialog = ({ onSubmit, onClose }) => {
    return (_jsxs(Dialog, { open: true, onClose: onClose, children: [_jsx(DialogTitle, { component: 'h3', children: _jsx(Typography, { children: 'Submit to teacher?' }) }), _jsxs(DialogContent, { dividers: true, children: [_jsx(Button, { onClick: () => {
                            onSubmit();
                            onClose();
                        }, startIcon: _jsx(Check, { color: '#61BA15' }), variant: 'contained', sx: { margin: '0px 15px' }, children: 'Yes' }), _jsx(Button, { onClick: onClose, startIcon: _jsx(X, { color: '#BD3D44' }), variant: 'outlined', sx: { margin: '0px 15px' }, children: 'No' })] })] }));
};
export default SubmissionConfirmationDialog;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getAssetIcon } from '../../services/asset.service';
import theme from '../../theme/customTheme';
import { useDialog } from '../Dialog/DialogProvider';
import { assignmentContentHeaderWrapper } from './assignment.styles';
import ContentHeaderButton from './ContentHeaderButton';
import FormattedDate from './FormattedDate';
import SubmissionConfirmationDialog from './SubmissionConfirmationDialog';
const AssignmentContentHeader = ({ asset, onSubmit, }) => {
    const [openDialog, closeDialog] = useDialog();
    const openConfirmationModal = () => openDialog({
        children: _jsx(SubmissionConfirmationDialog, { onSubmit: onSubmit, onClose: closeDialog })
    });
    const isSubmittable = asset.submittableStatus;
    const submissionStatus = (asset.submissions[0]?.submissionStatus ?? 'none');
    const lastSubmission = asset.submissions.find((sub) => sub.submittedOn);
    let submittedOn = null;
    if (lastSubmission) {
        submittedOn = lastSubmission.submittedOn;
    }
    return (_jsxs(Box, { sx: assignmentContentHeaderWrapper, children: [_jsxs(Box, { display: 'flex', children: [getAssetIcon(asset.assetType, asset.assetFileType, { stroke: '#000' }), _jsxs(Box, { marginLeft: '10px', children: [_jsx(Typography, { sx: { fontWeight: '600' }, children: asset.assetTitle }), _jsx(FormattedDate, { heading: 'Updated', date: asset.updatedAt })] })] }), _jsxs(Box, { sx: {
                    display: 'flex',
                    alignItems: 'center',
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: 'start'
                    }
                }, children: [_jsx(Box, { marginRight: '20px', sx: { [theme.breakpoints.down('sm')]: { paddingLeft: '34px' } }, children: _jsx(FormattedDate, { heading: 'Submitted', date: submittedOn }) }), isSubmittable && (_jsx(ContentHeaderButton, { onClick: openConfirmationModal, status: submissionStatus, hasSubmitted: asset.submissions[0] && asset.submissions[0].submissionStatus !== 'none' }))] })] }));
};
export default AssignmentContentHeader;

import { jsx as _jsx } from "react/jsx-runtime";
import ArticleIconExt from '/public/icons/assetIcons/ICON_article_ext.svg';
import ArticleIcon from '/public/icons/assetIcons/ICON_article_int.svg';
import AssessmentGuideIconExt from '/public/icons/assetIcons/ICON_assessmentguide_ext.svg';
import AssessmentGuideIcon from '/public/icons/assetIcons/ICON_assessmentguide_int.svg';
import AudioIconExt from '/public/icons/assetIcons/ICON_audio_ext.svg';
import AudioIcon from '/public/icons/assetIcons/ICON_audio_int.svg';
import CardsIconExt from '/public/icons/assetIcons/ICON_cards_ext.svg';
import CardsIcon from '/public/icons/assetIcons/ICON_cards_int.svg';
import EBookIconExt from '/public/icons/assetIcons/ICON_ebook_ext.svg';
import EBookIcon from '/public/icons/assetIcons/ICON_ebook_int.svg';
import ExampleIconExt from '/public/icons/assetIcons/ICON_example_ext.svg';
import ExampleIcon from '/public/icons/assetIcons/ICON_example_int.svg';
import GuideIconExt from '/public/icons/assetIcons/ICON_guide_ext.svg';
import GuideIcon from '/public/icons/assetIcons/ICON_guide_int.svg';
import HandoutIconExt from '/public/icons/assetIcons/ICON_handout_ext.svg';
import HandoutIcon from '/public/icons/assetIcons/ICON_handout_int.svg';
import ImageIconExt from '/public/icons/assetIcons/ICON_image_ext.svg';
import ImageIcon from '/public/icons/assetIcons/ICON_image_int.svg';
import InteractiveWebIconExt from '/public/icons/assetIcons/ICON_interactiveweb_ext.svg';
import InquiryLaunchLetterIcon from '/public/icons/assetIcons/ICON_launchletter.svg';
import PhysicalBookIcon from '/public/icons/assetIcons/ICON_physicalbook.svg';
import PosterIconExt from '/public/icons/assetIcons/ICON_poster_ext.svg';
import PosterIcon from '/public/icons/assetIcons/ICON_poster_int.svg';
import VideoIconExt from '/public/icons/assetIcons/ICON_video_ext.svg';
import VideoIcon from '/public/icons/assetIcons/ICON_video_int.svg';
import WebsiteIconExt from '/public/icons/assetIcons/ICON_website_ext.svg';
import WebsiteIcon from '/public/icons/assetIcons/ICON_website_int.svg';
export const getAssetIcon = (assetType, assetFileType, options) => {
    switch (assetType) {
        case 'RUBRIC':
        case 'QUIZ':
        case 'HANDOUT':
        case 'EXIT_TICKET':
            return assetFileType !== 'EXTERNAL_LINK'
                ? _jsx(HandoutIcon, { style: options })
                : _jsx(HandoutIconExt, { style: options });
        case 'ARTICLE':
        case 'TEXT':
            return assetFileType !== 'EXTERNAL_LINK'
                ? _jsx(ArticleIcon, { style: options })
                : _jsx(ArticleIconExt, { style: options });
        case 'IMAGE':
        case 'MAP':
        case 'INFOGRAPHIC':
            return assetFileType !== 'EXTERNAL_LINK'
                ? _jsx(ImageIcon, { style: options })
                : _jsx(ImageIconExt, { style: options });
        case 'CARDS':
            return assetFileType !== 'EXTERNAL_LINK'
                ? _jsx(CardsIcon, { style: options })
                : _jsx(CardsIconExt, { style: options });
        case 'POSTER':
            return assetFileType !== 'EXTERNAL_LINK'
                ? _jsx(PosterIcon, { style: options })
                : _jsx(PosterIconExt, { style: options });
        case 'PHYSICAL_BOOK':
            return _jsx(PhysicalBookIcon, { style: options });
        case 'E_BOOK':
            return assetFileType !== 'EXTERNAL_LINK'
                ? _jsx(EBookIcon, { style: options })
                : _jsx(EBookIconExt, { style: options });
        case 'SETUP_GUIDE':
            return assetFileType !== 'EXTERNAL_LINK'
                ? _jsx(GuideIcon, { style: options })
                : _jsx(GuideIconExt, { style: options });
        case 'CHECKPOINT_ASSESSMENT_GUIDE':
            return assetFileType !== 'EXTERNAL_LINK'
                ? _jsx(AssessmentGuideIcon, { style: options })
                : _jsx(AssessmentGuideIconExt, { style: options });
        case 'ASSESSMENT_GUIDE':
            return assetFileType !== 'EXTERNAL_LINK'
                ? _jsx(AssessmentGuideIcon, { style: options })
                : _jsx(AssessmentGuideIconExt, { style: options });
        case 'AUDIO':
            return assetFileType !== 'EXTERNAL_LINK'
                ? _jsx(AudioIcon, { style: options })
                : _jsx(AudioIconExt, { style: options });
        case 'READ_ALOUD':
        case 'VIDEO':
            return assetFileType !== 'EXTERNAL_LINK'
                ? _jsx(VideoIcon, { style: options })
                : _jsx(VideoIconExt, { style: options });
        case 'WEBSITE':
            return assetFileType !== 'EXTERNAL_LINK'
                ? _jsx(WebsiteIcon, { style: options })
                : _jsx(WebsiteIconExt, { style: options });
        case 'INTERACTIVE_WEB':
            return _jsx(InteractiveWebIconExt, { style: options });
        case 'EXAMPLE':
            return assetFileType
                ? _jsx(ExampleIcon, { style: options })
                : _jsx(ExampleIconExt, { style: options });
        case 'INQUIRY_LAUNCH_LETTER':
            return _jsx(InquiryLaunchLetterIcon, { style: options });
        default:
            return assetFileType !== 'EXTERNAL_LINK'
                ? _jsx(HandoutIcon, { style: options })
                : _jsx(HandoutIconExt, { style: options });
    }
};

import { jsx as _jsx } from "react/jsx-runtime";
import uuid from 'react-uuid';
import Typography from '@mui/material/Typography';
export const serializePlateObject = (nodes, link, compact) => {
    const determineMarginBySurroundingNodes = (index) => {
        const previousNode = nodes[index - 1];
        const nextNode = nodes[index + 1];
        if (previousNode && 'type' in previousNode && previousNode.type === 'ul') {
            return '5px 0px 0px 0px';
        }
        else if (nextNode && 'type' in nextNode && nextNode.type === 'ul') {
            return '0px';
        }
        else {
            return '0px 0px 5px 0px';
        }
    };
    const handleElementNode = (node, index) => {
        const key = `${node.id ?? uuid()}_${index}`;
        switch (node.type) {
            case 'paragraph':
            case 'p':
                return (_jsx("p", { style: index < nodes.length
                        ? { margin: determineMarginBySurroundingNodes(index) }
                        : { margin: '0px 0px 5px 0px' }, children: serializePlateObject(node.children, false, compact) }, key));
            case 'ul':
                return (_jsx("ul", { style: { margin: '0', paddingInlineStart: '24px' }, children: serializePlateObject(node.children, false, compact) }, key));
            case 'ol':
                return (_jsx("ol", { children: serializePlateObject(node.children, false, compact) }, key));
            case 'li':
                return (_jsx("li", { children: serializePlateObject(node.children, false, compact) }, key));
            case 'a':
                return (_jsx("span", { children: _jsx("a", { href: node.url, target: '_blank', rel: 'noreferrer', children: serializePlateObject(node.children, false, compact) }) }, key));
            default:
                return (_jsx("span", { children: serializePlateObject(node.children, false, compact) }, key));
        }
    };
    const handleTextNode = (node, link) => {
        return node.text ? (_jsx(Typography, { component: 'span', fontSize: 'inherit', fontWeight: node.bold ? 700 : 400, fontStyle: node.italic ? 'italic' : 'normal', lineHeight: compact ? 1.2 : 1.6, sx: node.underline ? {
                textDecoration: 'underline'
            } : {
                '&:hover': {
                    textShadow: link ? '0px 0px 0.7px' : ''
                }
            }, children: node.text }, `node_text${uuid()}`)) : _jsx("span", {}, uuid());
    };
    return nodes.map((node, index) => ('type' in node
        ? handleElementNode(node, index)
        : handleTextNode(node, link)));
};

import { createTheme } from '@mui/material/styles';
// Global overrides for MUI's component library:
let theme = createTheme({
    palette: {
        primary: {
            main: '#474A63',
            dark: '#343750'
        },
        secondary: {
            main: '#474A63'
        },
        text: {
            primary: '#323232',
            secondary: '#58606a',
        }
    },
    breakpoints: {
        values: {
            xs: 320,
            sm: 770,
            md: 990,
            lg: 1200,
            xl: 1460
        }
    }
});
theme = createTheme(theme, {
    typography: {
        fontFamily: '"Inter", sans-serif',
        body1: {
            fontSize: '16px',
            fontWeight: 500,
            color: theme.palette.text.primary
        },
        body2: {
            fontSize: '14px',
            fontWeight: 400,
            color: theme.palette.text.secondary
        },
        h1: {
            fontSize: '24px',
            fontWeight: 700,
            letterSpacing: '-0.15px',
            color: theme.palette.text.primary,
        },
        h2: {
            fontSize: '22px',
            fontWeight: 700,
            color: theme.palette.primary.main,
        },
        h3: {
            fontSize: '18px',
            fontWeight: 700,
            color: theme.palette.text.primary,
        },
        h4: {
            fontSize: '15px',
            fontWeight: 600,
            color: theme.palette.text.primary,
        },
        h5: {
            fontSize: '14px',
            fontWeight: 600,
            color: theme.palette.text.primary,
        },
        h6: {
            fontSize: '13px',
            fontWeight: 'bold',
            lineHeight: 2,
            letterSpacing: '-0.07px',
        },
        subtitle1: {
            fontSize: '16px',
            fontWeight: 400,
            color: theme.palette.text.secondary
        },
        subtitle2: {
            fontSize: '13px',
            fontWeight: 400,
            color: '#757575'
        }
    },
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    boxShadow: 'none',
                    border: 'solid 1px #ddd',
                    borderBottom: 'none',
                    margin: '3px 0px 0px 0px',
                    '&.Mui-expanded': {
                        margin: '3px 0px 0px 0px !important'
                    },
                    '&.first-child': {
                        borderRadius: '6px 6px 0px 0px',
                    },
                    '&.last-child': {
                        borderRadius: '0px 0px 6px 6px',
                        borderBottom: '1px solid #ddd !important'
                    },
                    '&.single': {
                        borderRadius: '6px',
                        borderBottom: '1px solid #ddd !important'
                    },
                    '&:before': {
                        position: 'relative'
                    }
                },
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    backgroundColor: '#f6f8fa',
                    borderRadius: '0px 0px 4px 4px',
                    borderTop: '1px solid #ddd'
                },
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    height: 48,
                    minHeight: '48px !important',
                    alignItems: 'center',
                },
                content: {
                    height: 48,
                    alignItems: 'center',
                    color: theme.palette.text.primary,
                    fontSize: 15,
                    fontWeight: 'bold'
                },
                expandIconWrapper: {
                    position: 'absolute',
                    left: 16,
                    '&.Mui-expanded': {
                        transform: 'rotate(90deg) !important'
                    }
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    padding: 0,
                    boxShadow: '0 2px 6px 0 rgba(0, 0, 30, 0.15)',
                    backgroundColor: '#fff !important',
                    alignItems: 'center',
                    marginBottom: '10px',
                    maxWidth: '30rem'
                },
                icon: {
                    padding: 0,
                },
                message: {
                    color: theme.palette.text.primary,
                    fontSize: 15
                },
                action: {
                    padding: 0
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: '#fff',
                    boxShadow: 'inset 0 -1px 0 0 #ddd'
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    height: 32,
                    borderRadius: 4,
                    fontSize: 16,
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    textAlign: 'center',
                    padding: '8px 15px'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontFamily: 'Inter'
                },
                contained: {
                    backgroundColor: theme.palette.primary.main,
                    color: '#FFF',
                    '&.Mui-disabled': {
                        backgroundColor: '#cbccd1 !important',
                    }
                },
                outlined: {
                    color: '#757575',
                    backgroundColor: '#FFF',
                    '&:hover': {
                        color: '#6a6f87 !important',
                        backgroundColor: '#fff !important'
                    },
                    '&.Mui-disabled': {
                        color: '#acb0b5 !important'
                    }
                },
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: 0
                }
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    marginRight: '-14px',
                    marginLeft: '8px'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.palette.text.primary,
                    padding: '7px 19px !important',
                    backgroundColor: '#fff',
                    '&.Mui-disabled': {
                        color: '#58606A',
                        backgroundColor: '#EDEDED',
                        height: '100%'
                    }
                },
                root: {
                    padding: '8px 0px !important',
                },
                '&.Mui-disabled': {
                    backgroundColor: '#EDEDED',
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    borderRadius: '6px !important',
                    boxShadow: '0 2px 4px 0 #00001e26 !important',
                    minWidth: '12rem !important'
                },
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: '#58606a !important',
                    '&:hover': { backgroundColor: '#4949641f !important' },
                    '&.Mui-selected': { backgroundColor: 'transparent' },
                    '&.Mui-selected.Mui-focusVisible': { backgroundColor: 'transparent' },
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    height: 40
                },
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: '"Inter", sans-serif',
                }
            }
        },
    }
});
export default theme;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from 'react';
import { FullScreen } from '@chiragrupani/fullscreen-react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CornersIn, CornersOut } from 'phosphor-react';
import buildWebViewer, { annotationsLoader, handleTools, loadNewDocument, overrideOrRevertHeaderStyles, resetReadyListener } from '../../services/pdf.service';
import theme from '../../theme/customTheme';
import { nonPDFAssetTypes } from '../../typings/assignmentTypings';
import { fullScreenButtonStyles, pdfViewerStyles } from './assignment.styles';
const PDFViewer = ({ setAnnotations, display, asset, setDocumentAndAnnotationsLoaded, }) => {
    const [instance, setInstance] = useState();
    const [previewLink, setPreviewLink] = useState('');
    const [fullScreen, setFullScreen] = useState(false);
    const viewer = useRef(null);
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    document.addEventListener('fullscreenchange', () => {
        if (document.fullscreenElement) {
            screen.orientation.lock('landscape');
        }
        else {
            screen.orientation.unlock();
        }
    });
    const shouldDisableTools = (assetInQuestion) => (Boolean(assetInQuestion.submissions[0] &&
        ['unreviewed', 'reviewed'].includes(assetInQuestion.submissions[0].submissionStatus)));
    // When a previewLink has been built - Enable WebViewer Instance
    useEffect(() => {
        if (previewLink && !instance) {
            buildWebViewer(viewer, setAnnotations, previewLink, setInstance);
        }
    }, [previewLink]);
    // On Instance Load - Ready the documentReady Listener
    useEffect(() => {
        if (instance) {
            resetReadyListener(instance, () => onLoad(asset));
            const iframe = document.getElementsByTagName('iframe')[0];
            if (iframe) {
                iframe.style.minHeight = '400px';
                iframe.style.paddingBottom = '50px';
            }
        }
    }, [instance]);
    useEffect(() => {
        if (instance) {
            overrideOrRevertHeaderStyles(instance, matches);
        }
    }, [matches, instance]);
    // On Asset change, ready the previewLink
    // If the instance exists, update the document
    useEffect(() => {
        if (!nonPDFAssetTypes.includes(asset.assetType) && asset.assetId) {
            if (asset.custom) {
                setPreviewLink(asset.assetLink);
            }
            else {
                const nextLink = `${process.env.API_URL}/content/asset/${asset.assetId}/view/${asset.activeContentId}`;
                setPreviewLink(nextLink);
            }
        }
        // If an instance exists, let's update the document
        if (instance) {
            setDocumentAndAnnotationsLoaded(false);
            loadNewDocument(instance, asset, () => {
                setDocumentAndAnnotationsLoaded(true);
                handleTools(instance, shouldDisableTools(asset));
            });
        }
    }, [asset.activeContentId]);
    useEffect(() => {
        if (instance) {
            handleTools(instance, shouldDisableTools(asset));
        }
    }, [asset.submissions]);
    // Use Callback to ensure freshly rendered instance on changes
    const onLoad = useCallback((selectedAsset) => {
        annotationsLoader(instance, selectedAsset).then(() => {
            setDocumentAndAnnotationsLoaded(true);
            handleTools(instance, shouldDisableTools(selectedAsset));
        });
    }, [instance]);
    const handleToggleFullscreen = () => {
        setFullScreen((previous) => !previous);
    };
    return (_jsx(Box, { height: '100%', display: 'grid', sx: { [theme.breakpoints.down('sm')]: { height: '100vh' } }, children: _jsx(FullScreen, { isFullScreen: fullScreen, onChange: (isFullScreen) => { setFullScreen(isFullScreen); }, children: _jsxs(Box, { height: '100%', children: [display && !matches && (_jsx(Tooltip, { title: 'Toggle full screen', enterDelay: 400, arrow: true, enterNextDelay: 300, children: _jsx(IconButton, { sx: fullScreenButtonStyles, onClick: handleToggleFullscreen, children: fullScreen
                                ? _jsx(CornersIn, { height: '30px', width: '30px' })
                                : _jsx(CornersOut, { height: '30px', width: '30px' }) }) })), _jsx(Box, { style: pdfViewerStyles(display ? 'contents' : 'none'), ref: viewer, id: 'viewer' })] }) }) }));
};
export default PDFViewer;

import { useSessionContext } from '../context/SessionContext';
import { useApiRequest } from './apiHook';
const useAccountUpdate = () => {
    const { authRequest } = useApiRequest();
    const [sessionContext] = useSessionContext();
    return {
        changePassword: (data) => {
            return authRequest({
                endpoint: '/students/change-password',
                method: 'PUT',
                data,
            });
        },
        updateAccount: (data) => {
            return authRequest({
                endpoint: `/students/${sessionContext.user._id}`,
                method: 'PUT',
                data,
            });
        },
    };
};
export { useAccountUpdate, };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSessionContext } from '../../context/SessionContext';
import { setDocumentTitle } from '../../helpers/documentHelper';
import { useApiRequest } from '../../hooks/apiHook';
import { selectorWrapper } from './inquiries.styles';
import UnitCard from './UnitCard';
const InquirySelector = () => {
    const { authRequest } = useApiRequest();
    const [session] = useSessionContext();
    const [inquiryData, setInquiryData] = useState([]);
    useEffect(() => {
        let mounted = true;
        setDocumentTitle('inquirED student Inquiries');
        authRequest({
            endpoint: '/students/content/inquiries',
            method: 'GET',
        }).then((response) => {
            if (mounted) {
                setInquiryData(response.data);
            }
        }).catch(console.error);
        return () => {
            mounted = false;
        };
    }, []);
    return (_jsxs(Box, { role: 'main', tabIndex: 0, sx: { overflowY: 'auto', padding: '39px 24px', width: '100%' }, children: [session.user._id &&
                _jsxs(Box, { sx: { marginBottom: '24px' }, children: [_jsx(Typography, { variant: 'h1', sx: { marginBottom: '12px' }, children: `Welcome, ${session?.user.firstName}!` }), _jsx(Typography, { variant: 'body1', sx: { marginBottom: '8px' }, children: session?.user.studentClass.studentClassName }), _jsx(Typography, { variant: 'body1', children: session?.user.teacher })] }), _jsx(Box, { "arial-label": 'inquiry list', tabIndex: 0, sx: selectorWrapper, children: inquiryData.map((inquiryItem) => (_jsx(UnitCard, { unit: inquiryItem }, `inquiryItem-${uuid()}`))) })] }));
};
export default InquirySelector;

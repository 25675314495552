import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MenuItem } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { ModuleDataContext } from '../../context/ModuleDataContext';
import { useSessionContext } from '../../context/SessionContext';
import { useLogout } from '../../hooks/loginHooks';
import theme from '../../theme/customTheme';
import Logo from '../Utils/Logo';
import { returnButtonStyles, returnButtonStylesAssignment, sessionMenuButtonStyles, skipLinkStyles, toolbarStyles } from './navigationBar.styles';
const NavigationBar = () => {
    const [sessionContext] = useSessionContext();
    const location = useLocation();
    const params = useParams();
    const { inquiryId, moduleId } = params;
    const { moduleNumber } = useContext(ModuleDataContext);
    const username = useMediaQuery(theme.breakpoints.down('sm'))
        ? `${sessionContext.user.firstName.charAt(0)} ${sessionContext.user.lastName.charAt(0)}`.toUpperCase()
        : `${sessionContext.user.firstName} ${sessionContext.user.lastName}`;
    const [anchorEl, setAnchorEl] = useState(null);
    // helper functions
    const logout = useLogout();
    const handleLogoutClick = () => {
        setAnchorEl(null);
        logout();
    };
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const menuOpen = Boolean(anchorEl);
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleSkipClick = () => {
        const mainContent = document.querySelector('[role="main"]');
        if (mainContent) {
            mainContent.focus();
        }
        else {
            console.error('cannot find role="main"');
        }
    };
    const returnButton = () => {
        const isDashboard = new RegExp(/(\/dashboard\/)(.)*/);
        const isModuleHomepage = new RegExp(/(\/overview\/)(.)*/);
        const isAssignmentView = new RegExp(/(\/assignment\/)(.)*/);
        if (isDashboard.test(location.pathname)) {
            return (_jsx(Button, { variant: 'contained', href: '/inquiries', startIcon: _jsx(ArrowBackIcon, {}), sx: returnButtonStyles, children: _jsx(Box, { component: 'span', children: 'Back to Inquiries' }) }));
        }
        //Note there is another back button ModuleOverview because the placement changes for mobile widths
        if (isModuleHomepage.test(location.pathname)) {
            return (_jsx(Button, { variant: 'contained', href: `/dashboard/${inquiryId}`, startIcon: _jsx(ArrowBackIcon, {}), sx: returnButtonStyles, children: _jsx(Box, { component: 'span', children: 'Back to Inquiry' }) }));
        }
        if (isAssignmentView.test(location.pathname)) {
            return (_jsx(Button, { variant: 'contained', href: `/overview/${inquiryId}/${moduleId}`, startIcon: _jsx(ArrowBackIcon, {}), sx: returnButtonStylesAssignment, children: _jsx(Box, { component: 'span', children: moduleNumber ? `Back to ${moduleNumber}` : 'Back to Module' }) }));
        }
    };
    return (_jsx(AppBar, { position: 'fixed', sx: {
            paddingRight: '0 !important',
            [theme.breakpoints.down('sm')]: { boxShadow: 'none' }
        }, children: _jsxs(Toolbar, { role: 'banner', sx: toolbarStyles, children: [_jsxs(Box, { display: 'flex', alignItems: 'center', children: [_jsx(Link, { id: 'skip-link', href: '#', onClick: handleSkipClick, tabIndex: 0, sx: skipLinkStyles, children: 'skip to main content' }), _jsx(Link, { href: '/inquiries', children: _jsx(Box, { display: 'flex', sx: { [theme.breakpoints.down('sm')]: { display: 'none' } }, alignItems: 'center', children: _jsx(Logo, { size: "141", sx: { marginTop: '8px' } }) }) }), returnButton()] }), _jsxs(Box, { children: [_jsx(Button, { id: 'account-button', "aria-controls": menuOpen ? 'account-menu' : undefined, "aria-haspopup": 'menu', "aria-expanded": menuOpen ? 'true' : undefined, "aria-label": 'account menu', onClick: handleMenuClick, sx: sessionMenuButtonStyles, children: username }), _jsxs(Menu, { id: 'account-menu', anchorEl: anchorEl, open: menuOpen, MenuListProps: {
                                'aria-labelledby': 'account-button',
                            }, onClose: handleCloseMenu, children: [_jsx(MenuItem, { component: RouterLink, to: '/account', onClick: handleCloseMenu, children: 'Account' }), _jsx(MenuItem, { onClick: handleLogoutClick, children: 'Logout' })] })] })] }) }));
};
export default NavigationBar;

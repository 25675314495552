import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSessionContext } from '../../context/SessionContext';
import { setDocumentTitle } from '../../helpers/documentHelper';
import { useAccountUpdate } from '../../hooks/accountHooks';
import { useApiRequest } from '../../hooks/apiHook';
import { serializePlateObject } from '../../services/plate/HTMLserializer';
import ModuleCard from '../Module/ModuleCard';
import * as inquiriesStyles from './inquiries.styles';
const InquiryHomepage = () => {
    const [sessionContext] = useSessionContext();
    const params = useParams();
    const { inquiryId } = params;
    const { updateAccount } = useAccountUpdate();
    const { authRequest } = useApiRequest();
    const [moduleData, setModuleData] = useState([]);
    const [inquiryData, setInquiryData] = useState();
    useEffect(() => {
        if (sessionContext.user._id) {
            updateAccount({
                lastInquiryVisited: inquiryId
            });
        }
    }, [sessionContext]);
    useEffect(() => {
        let mounted = true;
        if (mounted && inquiryId) {
            authRequest({ endpoint: `/students/content/inquiries/${inquiryId}` })
                .then((data) => {
                setInquiryData(data.data.inquiryData);
                setModuleData(data.data.moduleData);
            }).catch(console.error);
        }
        return () => { mounted = false; };
    }, [inquiryId]);
    useEffect(() => {
        setDocumentTitle('inquirED student Inquiry homepage');
    }, []);
    return (_jsxs(Box, { role: 'main', tabIndex: 0, sx: inquiriesStyles.inquiryHomepageWrapper, children: [inquiryData && (_jsxs(Box, { sx: inquiriesStyles.inquiryHomepageInfo, children: [_jsx(Box, { component: 'img', src: inquiryData.inquiryImages.title, alt: inquiryData.title, sx: { width: '100%', borderRadius: '13px', border: '2px solid #474A63' } }), _jsx(Typography, { component: 'h1', sx: inquiriesStyles.inquiryHomepageQuestion, children: inquiryData.inquiryQuestion }), _jsx(Typography, { component: 'div', variant: 'body1', sx: inquiriesStyles.inquiryHomepageDescription, children: serializePlateObject(inquiryData.description) })] })), moduleData &&
                _jsx(Box, { tabIndex: 0, "aria-label": 'module list', sx: inquiriesStyles.inquiryHomepageModuleSelector, children: moduleData.map((moduleItem) => (_jsx(ModuleCard, { module: moduleItem, inquiryId: String(inquiryId) }, moduleItem._id))) })] }));
};
export default InquiryHomepage;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { Eye, EyeSlash } from 'phosphor-react';
import * as yup from 'yup';
import { getSSOAuthRedirect } from '../../helpers/authHelpers';
import { setDocumentTitle } from '../../helpers/documentHelper';
import { useAuthFormikInputFieldBuilder, useLogin, useSignUp, useVerifyLogin } from '../../hooks/loginHooks';
import { IdentityProvider } from '../../typings/authTypings';
import { signUpFormStyles } from './auth.styles';
import TermsOfService from './TermsOfService';
const SignUpForm = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState({ password: false, confirmPassword: false });
    const [usernameExists, setUsernameExists] = useState(false);
    const { studentClassId = '' } = useParams();
    const [teacherName, setTeacherName] = useState('');
    const [usernameOne, setUsernameOne] = useState('');
    const [usernameTwo, setUsernameTwo] = useState('');
    const [error, setError] = useState('');
    const [isSignupDisabled, setIsSignupDisabled] = useState(false);
    const login = useLogin();
    const signUp = useSignUp();
    const verifyLogin = useVerifyLogin();
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            username: '',
            password: '',
            passwordConfirm: '',
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            username: yup.string().matches(/^(?:[A-Z\d][A-Z\d_-]{1,100}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i, 'Username invalid').required('Username Required'),
            firstName: yup.string().matches(/^[A-Za-z '-]+$/i, 'First Name invalid format').required('First Name Required'),
            lastName: yup.string().matches(/[A-Za-z '-]+$/i, 'Last Name invalid format').required('Last Name Required'),
            password: yup.string().min(6, 'Password must be at least 6 characters').required('Password Required'),
            passwordConfirm: yup.string()
                .oneOf([yup.ref('password'), null], 'Passwords must match')
                .required('Passwords must match'),
        }),
        onSubmit: (formData) => {
            const { firstName, lastName, username, password } = formData;
            //Disable Signup Button during user creation
            setIsSignupDisabled(true);
            signUp({
                firstName,
                lastName,
                username,
                password,
                studentClassId,
            }).then(() => {
                login(username, password, (err) => {
                    console.error('Error occurred - ', err);
                });
            }).catch((err) => {
                //Re-enable signup after error + 1 sec delay
                setTimeout(() => {
                    setIsSignupDisabled(false);
                }, 1000);
                if (err.response.status === 409) {
                    console.warn(err.response.data.error);
                    const [usernameOne, usernameTwo] = err.response.data.data;
                    setUsernameOne(usernameOne);
                    setUsernameTwo(usernameTwo);
                    setUsernameExists(true);
                    document.getElementById('username')?.focus();
                }
                else {
                    console.error('Error occurred - ', err);
                    setError('An error occurred, please try again later or contact your teacher for help.');
                }
            });
        }
    });
    // Adding a formik field builder to the form significantly
    // reduces cyclomatic complexity
    const textFieldBuilder = useAuthFormikInputFieldBuilder(formik);
    const handleAlternativeUsername = (username) => {
        formik.setFieldValue('username', username);
        setUsernameExists(false);
        setUsernameOne('');
        setUsernameTwo('');
    };
    const getFirstErrorKey = (object) => {
        const firstErrorKey = Object.keys(object)[0];
        return firstErrorKey ?? '';
    };
    useEffect(() => {
        if (!formik.isValid && formik.isSubmitting) {
            const firstErrorKey = getFirstErrorKey(formik.errors);
            global.window.document.getElementsByName(firstErrorKey)[0]?.focus();
        }
    }, [formik.isValid, formik.errors, formik.isSubmitting]);
    useEffect(() => {
        if (studentClassId) {
            verifyLogin(studentClassId)
                .then((res) => {
                const { verified } = res.data.data;
                if (!verified) {
                    console.error('Error Occurred - Invalid Class ID');
                    navigate('/bad-link');
                    return;
                }
                else if (verified.studentClass.isArchived) {
                    console.error('Error Occurred - Archived Class ID');
                    navigate('/bad-link');
                    return;
                }
                if (verified.rosterType !== 'MANUAL') {
                    // SSO flow:
                    const redirectURI = getSSOAuthRedirect(IdentityProvider[verified.rosterType], studentClassId);
                    window.location.href = redirectURI;
                    return;
                }
                setTeacherName(verified.teacherName);
            })
                .catch((err) => {
                console.error('Error Occurred - ', err);
                navigate('/bad-link');
            });
        }
        else {
            console.error('Error Occurred - Invalid Class ID');
            navigate('/bad-link');
        }
    }, [studentClassId]);
    useEffect(() => {
        setDocumentTitle('inquirED student sign-up');
    }, []);
    return (_jsxs(Box, { role: 'main', component: 'form', noValidate: true, onSubmit: formik.handleSubmit, "data-testid": 'SignUpForm', marginTop: '30px', sx: signUpFormStyles, children: [_jsxs(Box, { marginBottom: '30px', sx: {
                    '@media (max-height: 900px)': {
                        marginBottom: '10px'
                    }
                }, children: [_jsx(Typography, { variant: 'h2', component: 'h1', children: 'Welcome!' }), _jsx(Typography, { variant: 'subtitle1', component: 'p', children: `Teacher: ${teacherName}` })] }), _jsx(Box, { marginBottom: '30px', sx: { '@media (max-height: 900px)': { marginBottom: '10px' } }, children: textFieldBuilder('username', 'Create Username', 'text', null) }), _jsx(Box, { marginBottom: '16px', sx: { '@media (max-height: 900px)': { marginBottom: 0 } }, children: textFieldBuilder('firstName', 'First Name', 'text', null) }), _jsx(Box, { marginBottom: '30px', sx: { '@media (max-height: 900px)': { marginBottom: '10px' } }, children: textFieldBuilder('lastName', 'Last Name', 'text', null) }), _jsx(Box, { marginBottom: '16px', sx: { '@media (max-height: 900px)': { marginBottom: 0 } }, children: textFieldBuilder('password', 'Password', showPassword.password ? 'text' : 'password', (_jsx(InputAdornment, { position: 'end', sx: { marginRight: '10px' }, children: _jsx(IconButton, { "aria-label": "Toggle Password Visibility", "data-testid": 'password-toggle-button', onClick: () => setShowPassword({
                            ...showPassword, password: !showPassword.password
                        }), children: showPassword.password ? _jsx(Eye, {}) : _jsx(EyeSlash, {}) }) })), {
                    FormHelperTextProps: {
                        sx: {
                            position: 'absolute',
                            bottom: '8px',
                            right: '40px'
                        }
                    }
                }) }), _jsx(Box, { marginBottom: '30px', children: textFieldBuilder('passwordConfirm', 'Confirm Password', showPassword.password ? 'text' : 'password', (_jsx(InputAdornment, { position: 'end', sx: { marginRight: '10px' }, children: _jsx(IconButton, { "aria-label": "Toggle Password Visibility", "data-testid": 'password-toggle-button', onClick: () => setShowPassword({
                            ...showPassword, password: !showPassword.password
                        }), children: showPassword.password ? _jsx(Eye, {}) : _jsx(EyeSlash, {}) }) })), {
                    FormHelperTextProps: {
                        sx: {
                            position: 'absolute',
                            bottom: '8px',
                            right: '40px'
                        }
                    }
                }) }), _jsxs(Box, { marginBottom: '16px', children: [_jsx(Button, { "data-testid": 'SignUp', type: 'submit', variant: 'contained', fullWidth: true, disabled: isSignupDisabled, sx: { marginBottom: '12px' }, children: 'Sign Up' }), _jsx(Button, { "data-testid": 'already-have-account-btn', onClick: () => navigate('/auth/login'), variant: 'outlined', fullWidth: true, children: 'I already have an account' })] }), _jsx(Box, { padding: '12px 0', borderTop: '1px solid #DDDDDD', marginTop: '20px', children: _jsx(TermsOfService, {}) }), usernameExists &&
                _jsxs(Box, { role: 'alert', children: [_jsx(Typography, { color: 'error', children: 'That username already exists.' }), _jsx(Typography, { color: 'error', children: 'Instead, how about:' }), _jsxs(Typography, { color: 'error', children: [_jsx(Button, { onClick: () => handleAlternativeUsername(usernameOne), sx: { color: '#d3302f', margin: 0, padding: 0 }, children: usernameOne }), ' ', 'or', ' ', _jsx(Button, { onClick: () => handleAlternativeUsername(usernameTwo), sx: { color: '#d3302f', margin: 0, padding: 0 }, children: usernameTwo })] })] }), error &&
                _jsx(Typography, { color: 'error', children: error })] }));
};
export default SignUpForm;

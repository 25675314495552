import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { FeedbackContext } from '../../context/FeedbackContext';
import { useApiRequest, useQuery } from '../../hooks/apiHook';
export default () => {
    const navigate = useNavigate();
    const { setFeedback } = useContext(FeedbackContext);
    const { publicRequest } = useApiRequest();
    const query = useQuery();
    const code = query.get('code');
    const dataState = query.get('state');
    useEffect(() => {
        publicRequest({ endpoint: `/account/sso?code=${code}&state=${dataState}` })
            .then((result) => {
            const { data } = result;
            localStorage.setItem('AccessToken', data.tokens.access_token);
            localStorage.setItem('IdToken', data.tokens.id_token);
            localStorage.setItem('RefreshToken', data.tokens.refresh_token);
            localStorage.setItem('Expires', String(Date.now() + (1000 * 60 * 60))); // Now + 1 hour
            localStorage.setItem('LastVisitedOn', String(Date.now()));
            window.location.href = '/';
        })
            .catch((err) => {
            const { message, redirectPathname } = err.response.data;
            console.error(message);
            if (redirectPathname) {
                navigate(redirectPathname);
                return;
            }
            setFeedback({
                message: err.response.data.message,
                severity: 'error',
                open: true,
                disableAutoHide: true,
            });
            navigate('/auth/login');
        });
    }, []);
    return (_jsx(Box, { width: '100%', display: 'flex', justifyContent: 'center', children: _jsx(Box, { width: '500px', margin: 'auto', children: _jsx(CircularProgress, {}) }) }));
};

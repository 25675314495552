import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { Slide, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Done from '../../../public/badges/Badge_Done_inverted.svg';
import RevisitInverted from '../../../public/badges/Badge_Revisit_inverted.svg';
import ToSubmitInverted from '../../../public/badges/Badge_ToSubmit_inverted.svg';
import { getAssetIcon } from '../../services/asset.service';
import { assetBadgeStyles, drawerAssetDescription, drawerAssetIconStyles, drawerAssetIconWrapperStyles, drawerAssetItemStyles, drawerAssetTagStyles } from './assignment.styles';
const getAssetItemColor = (submittable, status, hasSubmitted) => {
    if (submittable) {
        if (status === 'none' && !hasSubmitted) {
            return '#E7AE25';
        }
        else if (status === 'revisit') {
            return '#E86666';
        }
    }
    return '#D8D8D8';
};
const SubmissionStatusIcon = ({ status, hasSubmitted }) => {
    const dimensions = { height: '27px', width: '27px' };
    if (status === 'none' && !hasSubmitted) {
        return _jsx(ToSubmitInverted, { ...dimensions });
    }
    else if (status === 'revisit') {
        return _jsx(RevisitInverted, { ...dimensions });
    }
    else {
        return _jsx(Done, { ...dimensions });
    }
};
const DrawerAssetItem = ({ asset, collapsed, selectedAsset, handleSelectAsset, }) => {
    const containerRef = useRef(null);
    const [assetItemColor, setAssetItemColor] = useState('#D8D8D8');
    useEffect(() => {
        setAssetItemColor(getAssetItemColor(asset.submittableStatus, asset.submissions[0]?.submissionStatus ?? 'none', asset.submissions.length > 1));
    }, [asset]);
    return (_jsxs(Box, { ref: containerRef, component: 'button', className: 'drawer-asset-item', onClick: handleSelectAsset, sx: drawerAssetItemStyles(collapsed, (asset._id === selectedAsset._id), assetItemColor), children: [_jsx(Box, { sx: drawerAssetTagStyles(collapsed, assetItemColor), children: asset.submittableStatus && (_jsx(Box, { sx: assetBadgeStyles(collapsed), children: _jsx(SubmissionStatusIcon, { status: asset.submissions[0]?.submissionStatus ?? 'none', hasSubmitted: asset.submissions.length > 1 }) })) }), _jsx(Box, { sx: drawerAssetIconWrapperStyles(collapsed), children: getAssetIcon(asset.assetType, asset.assetFileType, drawerAssetIconStyles(collapsed, asset.submittableStatus)) }), _jsx(Slide, { in: !collapsed, direction: 'left', easing: 'ease-out', container: containerRef.current, children: _jsx(Typography, { sx: drawerAssetDescription, children: asset.assetTitle }) })] }));
};
export default DrawerAssetItem;

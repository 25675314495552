import jwt_decode from 'jwt-decode';
export const decodeToken = (token) => {
    try {
        return jwt_decode(String(localStorage.getItem(token)));
    }
    catch (e) {
        return null;
    }
};
export const isExpired = () => {
    const unixTime = parseInt(String(localStorage.getItem('Expires')));
    if (isNaN(unixTime)) {
        return true;
    }
    return (unixTime < Date.now());
};
export const setTokenStorage = (result) => {
    localStorage.setItem('AccessToken', result.AuthenticationResult.AccessToken);
    localStorage.setItem('IdToken', result.AuthenticationResult.IdToken);
    localStorage.setItem('RefreshToken', result.AuthenticationResult.RefreshToken);
    localStorage.setItem('Expires', String(Date.now() + (1000 * 60 * 60))); // Now + 1 hour
};
export const getSSOAuthRedirect = (idp, studentClassId) => {
    //format the dataState object into a string and then uri encode it for use in the URL
    const dataStateString = JSON.stringify({ userPlatform: 'student', identityProvider: idp, studentClassId });
    const encodedDataState = encodeURIComponent(dataStateString);
    return (`https://${process.env.COGNITO_OAUTH_HOST}/oauth2/authorize?identity_provider=${idp}&redirect_uri=${process.env.LITTLE_DESK_URL}/sso&response_type=CODE&client_id=${process.env.COGNITO_CLIENT_ID}&scope=email%20openid%20aws%2Ecognito%2Esignin%2Euser%2Eadmin&state=${encodedDataState}`);
};

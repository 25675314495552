import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { nonPDFAssetTypes } from '../../typings/assignmentTypings';
import { assignmentContentWrapper } from './assignment.styles';
import AssignmentContentHeader from './AssignmentContentHeader';
import AudioPlayer from './AudioPlayer';
import ExternalAsset from './ExternalAsset';
import ImageViewer from './ImageViewer';
import PDFViewer from './PDFViewer';
import VideoPlayer from './VideoPlayer';
const ViewerHeader = ({ asset, onSubmit }) => {
    const isExternal = asset.assetFileType === 'EXTERNAL_LINK' || nonPDFAssetTypes.includes(asset.assetType);
    return isExternal
        ? (_jsx(ExternalAsset, { asset: asset }))
        : (_jsx(AssignmentContentHeader, { onSubmit: onSubmit, asset: asset }));
};
const AssetViewer = ({ asset }) => {
    const handleAssetType = () => {
        switch (asset.assetType) {
            case 'VIDEO':
                return _jsx(VideoPlayer, { asset: asset });
            case 'READ_ALOUD':
            case 'AUDIO':
                return _jsx(AudioPlayer, { asset: asset });
            case 'IMAGE':
                return _jsx(ImageViewer, { asset: asset });
            default:
                return null;
        }
    };
    return (_jsx(_Fragment, { children: asset.assetFileType !== 'EXTERNAL_LINK' && handleAssetType() }));
};
const AssignmentContent = ({ selectedAsset, submitAssignment, setAnnotations, setDocumentAndAnnotationsLoaded, collapsed, }) => {
    const handlePDFViewerDisplay = () => {
        const fileExt = selectedAsset.assetLink.split('.').pop();
        return fileExt === 'pdf';
    };
    return (_jsx(Box, { role: 'main', tabIndex: 0, sx: assignmentContentWrapper(collapsed), children: selectedAsset &&
            _jsxs(_Fragment, { children: [_jsx(ViewerHeader, { onSubmit: submitAssignment, asset: selectedAsset }), handlePDFViewerDisplay() &&
                        _jsx(PDFViewer, { setDocumentAndAnnotationsLoaded: setDocumentAndAnnotationsLoaded, setAnnotations: setAnnotations, display: true, asset: selectedAsset }), !handlePDFViewerDisplay() &&
                        _jsx(AssetViewer, { asset: selectedAsset })] }) }));
};
export default AssignmentContent;

import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Button from '@mui/material/Button';
import styled from '@mui/system/styled';
import RevisitIcon from '../../../public/icons/buttonIcons/revisitIcon.svg';
import ToSubmitIcon from '../../../public/icons/buttonIcons/toSubmitIcon.svg';
import theme from '../../theme/customTheme';
const DefaultButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'hasSubmitted' && prop !== 'status',
})(() => ({
    padding: '15px 25px',
    borderRadius: '24px',
}));
const ContentHeaderButton = React.forwardRef((props, ref) => {
    const shouldDisableButton = Boolean(props.hasSubmitted && ['unreviewed', 'reviewed'].includes(props.status));
    /**
     * switch case that returns props for the button based on status
     * @param status submission status
     * @returns object with correct prop info
     */
    const setProps = (status, hasSubmitted) => {
        if (status === 'revisit') {
            return {
                icon: _jsx(RevisitIcon, { style: { height: '22px', width: '22px' } }),
                text: 'Resubmit',
                borderColor: '#E86666'
            };
        }
        else if (status === 'none' && !hasSubmitted) {
            return {
                icon: _jsx(ToSubmitIcon, { style: { height: '22px', width: '22px' } }),
                text: 'Submit',
                borderColor: '#E7AA34'
            };
        }
        else {
            return {
                icon: null,
                text: 'Submit',
                borderColor: 'none'
            };
        }
    };
    /**
     * Destructure props
     * @param icon startIcon
     * @param text button text
     * @param borderColor button border color
     */
    const { icon, text, borderColor } = setProps(props.status, props.hasSubmitted);
    return (_jsx(DefaultButton, { ...props, disabled: shouldDisableButton, startIcon: icon, sx: {
            border: `5px solid ${borderColor}`,
            [theme.breakpoints.down('sm')]: { alignSelf: 'center', marginTop: '10px', }
        }, variant: 'contained', ref: ref, children: text }));
});
export default ContentHeaderButton;
